[widget-id='OVTwidget'] {
@import "../../mixins/global";

.Background {
  .overPageContainer {
    margin-bottom: 10px;
  }

  .radioContainer {
    margin-top: 25px;
  }

  .description {
    color: $dark-blue-button-color;
    font-family: $neue-grotesk-font;
    font-size: 17px;
    margin-top: 10px;

    b {
      color: $dark-blue-button-color;
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
    }

    @include phone-and-tablet-portrait {
      font-size: 16px;
    }
  }

  .radioButton {
    font-family: $neue-grotesk-medium-font;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;

    label {
      margin-left: 8px;
    }
  }

  .footer {
    padding-bottom: 5px;
  }

  .footerRightButton {
    min-width: unset;
  }

  .textareaContainer {
    margin-top: 25px;
  }
}

.pageBody {
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding-left: 14.625rem;
  padding-right: 14.625rem;
  padding-top: 5rem;
  border-radius: 36px;
  background: white;
  height: 100%;

  @include phone-and-tablet-portrait {
    padding: 0;
    border-radius: 36px 36px 0px 0px;
  }
}

}