[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.select {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 12px 32px 12px 16px;
  border-radius: 10px;
  border: none;
  box-shadow: 0 0 0 1px $solid-05;
  background: white;

  color: $tint-05-dark;
  font-family: $poppins-medium-font;
  font-size: 14px;
  font-weight: 500;
  appearance: none;
  outline: none;

  /* Hide arrow icon in IE browsers */
  &::-ms-expand {
    display: none;
  }

  &:focus {
    box-shadow: 0 0 0 2px $dark-blue-button-color, $input-shadow;
  }

  option {
    color: $tint-05-dark;
    font-family: $poppins-medium-font;
    font-size: 14px;
    font-weight: 500;
  }

  &.inputWithIcon {
    border-left: 36px solid transparent;
  }

  &[value="null"] {
    background: red;
  }
}

.container {
  position: relative;
}

.icon {
  position: absolute;
  top: 12px;
  left: 14px;

  width: 20px;
  height: 20px;
  filter: $filter-tint-05-dark;
}

.dropdownArrow {
  position: absolute;
  top: 16px;
  right: 16px;

  width: 16px;
  height: 16px;
  filter: $filter-tint-05-dark;
  pointer-events: none;
}

.error {
  .icon {
    filter: $error-filter;
  }

  .select {
    color: $error;
    box-shadow: 0 0 0 1px $error;

    &:focus {
      box-shadow: 0 0 0 2px $error, $input-shadow;
    }
  }
}

.warning {
  .select {
    box-shadow: 0 0 0 1px #e98c00;

    &:focus {
      box-shadow: 0 0 0 2px #e98c00, $input-shadow;
    }
  }
}

.showingPlaceholder {
  .select {
    color: $base-other-color-1;
  }

  &.middleValueMenu {
    .select {
      color: transparent;
    }
  }
}

.middleValueMenu {
  .select {
    color: transparent;
  }
}

.placeholder {
  display: none;
}

.isMenuOpen {
  .dropdownArrow {
    transform: rotate(180deg);
  }
}

}