[widget-id='OVTwidget'] {
@import "../../mixins/global";

.SetupCalibrationRulerPage {
  width: 100vw;
  height: var(--vh-nobars, 100vh);
  position: absolute;
  top: 0;
  bottom: 0;
  overflow: hidden;

  .backgroundVideo {
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .borderTop {
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    top: 20px;
    background: white;
    animation: ani-top 1600ms ease-in forwards;
  }

  @keyframes ani-top {
    0% {
      width: 0;
    }

    57% {
      width: 0;
    }

    65% {
      width: 32%;
    }

    87% {
      width: 65%;
    }

    100% {
      width: 100%;
    }
  }

  .borderBottom {
    height: 2px;
    width: 100%;
    position: absolute;
    left: 0;
    background: white;
    animation: ani-btm 1600ms ease-in forwards;
    transition: top 300ms ease-out;
  }

  @keyframes ani-btm {
    0% {
      width: 0;
    }

    57% {
      width: 0;
    }

    65% {
      width: 32%;
    }

    87% {
      width: 65%;
    }

    100% {
      width: 100%;
    }
  }

  .borderLeft {
    height: 100%;
    width: 2px;
    position: absolute;
    left: 73px;
    bottom: 0;
    background: white;
    animation: ani-left 1600ms ease-in forwards;
  }

  @keyframes ani-left {
    0% {
      height: 0;
    }

    57% {
      height: 0;
    }

    65% {
      height: 32%;
    }

    87% {
      height: 65%;
    }

    100% {
      height: 100%;
    }
  }

  .testBg {
    position: absolute;
    left: 73px;
    top: 20px;
    background: white;
    width: calc(100vw - 73px);
    opacity: 0;
    transition: height 300ms ease-out;

    &.testBgAnimation {
      animation: 0.6s ease-in fadeInAniBg;
      animation-fill-mode: forwards;
    }

    @keyframes fadeInAniBg {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .creditCard {
    display: block;
    width: auto;
    height: 281px;
    position: absolute;
    left: 129px;
    animation: ani-card-in 800ms ease-out forwards,
      ani-card-out 800ms ease-out forwards 2800ms;

    @keyframes ani-card-in {
      0% {
        left: 129px;
        opacity: 0;
      }

      100% {
        left: 0;
        opacity: 0.5;
      }
    }

    @keyframes ani-card-out {
      0% {
        opacity: 0.5;
      }

      100% {
        opacity: 0.2;
      }
    }
  }

  .cardTips {
    width: 263px;
    height: auto;
    position: absolute;
    left: -45px;
    top: 15px;
    animation: ani-uptips-in 800ms ease-out forwards,
      ani-uptips-out 800ms ease-out forwards 3800ms;

    .cardTipsTail {
      left: 50px;
    }

    @keyframes ani-uptips-in {
      0% {
        opacity: 0;
        top: 45px;
      }

      100% {
        opacity: 1;
        top: 15px;
      }
    }

    @keyframes ani-uptips-out {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }

  .operateArea {
    height: 140px;
    width: 100%;
    box-sizing: border-box;
    padding: 15px 25px;
    background: white;
    position: absolute;
    left: 0;
    bottom: 0;

    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .slider {
      width: 100%;
    }

    .operateComp {
      height: 48px;
      width: 325px;
      max-width: 100%;
      position: relative;
      margin: 0 auto;
    }
  }

  .circleWrapper {
    width: 48px;
    position: absolute;
    z-index: 10;
    left: 48px;
    top: 0;
    border-radius: 50%;
    background-color: #8f919e;
    transition: background-color 300ms ease-out;

    img {
      width: 22px;
      height: 14px;
      pointer-events: none;
      filter: none;
    }

    &.circleWrapperEnabled {
      background-color: $dark-blue-button-color;
    }
  }

  .circleTips {
    width: 350px;
    max-width: calc(100vw - (#{$phone-padding} * 2));
    height: auto;
    position: absolute;
    left: -48px;
    bottom: calc(
      48px + 8px + 5px
    ); //48px = button height, 8px = tooltip tail height, 5px = space between tooltip and button
    animation: ani-belowtips-in 800ms ease-out forwards,
      ani-belowtips-out 800ms ease-out forwards 6800ms;

    .circleTipsTail {
      left: calc(48px + 24px); //48px = button height, 24px = button half height
    }

    @keyframes ani-belowtips-in {
      0% {
        opacity: 0;
        bottom: calc(48px + 8px + 5px + 30px);
      }

      100% {
        opacity: 1;
        bottom: calc(
          48px + 8px + 5px
        ); //48px = button height, 8px = tooltip tail height, 5px = space between tooltip and button
      }
    }

    @keyframes ani-belowtips-out {
      0% {
        opacity: 1;
      }

      100% {
        opacity: 0;
      }
    }
  }

  .btnMinus,
  .btnAdd {
    position: absolute;
    z-index: 10;
    background-color: white;
    border: 1px solid #f0f0f0;
    transition: opacity 300ms ease-out;
    width: 48px;
  }

  .btnMinus {
    top: 0;
    transform: translateX(-100%);

    img {
      width: 16px;
      height: 3px;
    }
  }

  .minusShadow {
    box-shadow: $button-shadow;
  }

  .btnAdd {
    top: 0;

    img {
      width: 13px;
      height: 13px;
    }
  }

  .addShadow {
    box-shadow: $button-shadow;
  }

  .scale {
    width: 230px;
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    left: 48px;
    top: 14px;
    box-sizing: border-box;
    border: 1px solid #f0f0f0;
    z-index: 1;

    img {
      width: 210px;
      height: 8px;
      pointer-events: none;
    }
  }

  .progress {
    position: absolute;
    left: 48px;
    top: 14px;
    height: 20px;
    width: 0;
    background-color: $border-color;
    z-index: 0;
  }

  .finishBtn {
    width: 100%;
  }

  .rowButtons {
    margin-top: 25px;
    display: flex;
    gap: 10px;
    align-items: center;
    width: 100%;
    justify-content: space-between;
  }
}

}