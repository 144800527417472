[widget-id='OVTwidget'] {
@import "../../mixins/global";

.LegalDisclaimer {
  .LegalDisclaimerBody {
    padding-top: 30px;
    padding-bottom: 200px;
    color: $dark-blue-button-color;
    background-color: white; //padding-top: 30px;

    @include phone-and-tablet-portrait {
      padding: 30px $phone-padding 20px;
      width: 100%;
      margin-bottom: 0;
      min-height: fit-content;
    }
  }

  .center {
    width: 100%;
    text-align: center;
  }

  .title {
    font-family: $poppins-medium-font;
    font-size: 24px;
    text-align: center;
    margin-bottom: 1.5rem;
  }

  .sectionTitle {
    font-family: $neue-grotesk-medium-font;
    font-size: 16px;
    font-weight: 500;
    color: $dark-blue-button-color;
    margin: 30px 0 10px;
  }

  .descriptionText {
    font-family: $neue-grotesk-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.4;
    letter-spacing: -0.42px;
    color: $dark-blue-button-color;
  }

  .checkboxRow {
    font-family: $neue-grotesk-font;
    font-size: 14px;
    font-weight: bold;
    color: $dark-blue-button-color;
    margin-bottom: 22px;
    display: flex;
    align-items: center;

    @include desktop-and-tablet-landscape {
      margin: 0 25px 0 0;
    }
  }

  .confirmButton {
    width: 100%;
    margin: 25px 0;
  }

  .complianceStatements {
    font-family: $neue-grotesk-font;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: -0.36px;
    color: $dark-blue-button-color;
  }

  .complianceEssilor {
    font-family: $neue-grotesk-font;
    font-size: 12px;
    line-height: 1.4;
    letter-spacing: -0.36px;
    color: $dark-blue-button-color;
    margin-top: 20px;

    img {
      margin-right: 5px;
      margin-bottom: -2px;
    }
  }
}

}