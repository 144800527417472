[widget-id='OVTwidget'] {
@import "../../mixins/global";

.YouWillNeedContainer {
  display: flex;
  flex-direction: column;
  background: white;
  gap: 15px;
  padding-bottom: 120px;
  height: 100%;

  @include phone-and-tablet-portrait {
    width: 100%;
    align-items: center;
  }

  @include desktop-and-tablet-landscape {
    width: 100%;
    border: 1px solid $border-color;
    border-radius: 40px;
    padding: 60px 24px;
    margin: auto;
    justify-content: center;
    align-items: center;
  }

  .YouWillNeedTitle {
    width: 40vw;
    max-width: 760px;
    font-family: $poppins-medium-font;
    font-size: 32px;
    text-align: left;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.32px;

    @include phone-and-tablet-portrait {
      width: 90vw;
      margin-top: 40px;
      margin-bottom: 20px;
      font-size: 32px;
      line-height: 36px;
      letter-spacing: 0em;
    }
  }

  .YouWillNeedContent {
    font-family: $neue-grotesk-font;
    width: 40vw;
    max-width: 760px;
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    letter-spacing: 0em;
    text-align: left;
  }

  .YouWillNeedElement {
    font-family: $neue-grotesk-font;
    width: 40vw;
    max-width: 760px;
    font-size: 18px;
    text-align: left;
    display: flex;
    align-items: center;
    gap: 15px;
    justify-content: flex-start;

    border-radius: 12px;
    border: solid 1px $border-color;

    &.hasAboutSection:hover {
      cursor: pointer;
    }

    .ElementText {
      width: 80%;
      padding: 15px;
      font-family: $neue-grotesk-font;
      font-size: 16px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.4;
      letter-spacing: -0.48px;
      text-align: left;
      color: $dark-blue-button-color;
    }

    .ElementIcon {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;
      width: 48px;
      height: 48px;
      border-radius: 100px;
      background: $background-gradient-color;

      img {
        padding: 16px;
      }
    }

    .aboutIcon {
      width: 22px;
      margin-right: 15px;
      margin-left: auto;
      cursor: pointer;
      background-color: transparent;
      border: none;
      padding: 0;
    }

    @include phone-and-tablet-portrait {
      width: 90vw;
      font-size: 16px;
      line-height: 22.4px;
    }
  }
}

}