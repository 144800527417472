[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.Button {
  border-radius: 12px;
  padding: 10px 16px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  min-height: 48px;
  min-width: 48px;
  color: black;

  font-family: $neue-grotesk-medium-font;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;

  &:disabled {
    cursor: default;
    box-shadow: inset 0px 0px 2px rgb(255 255 255 / 48%);
    opacity: 0.4;
  }

  &:not(:disabled):hover {
    transition: opacity 0.2s;
    opacity: 0.9;
  }
}

.default {
  color: $black-transparent-button;
  font-family: $neue-grotesk-font;
  font-size: 16px;
  font-weight: 400;
  min-height: auto;
  text-decoration: underline;
  border: none;
  background-color: transparent;
  padding: 0;
}

.dark {
  background-color: $dark-blue-button-color;
  color: $fluorescent-blue-text-button-color;
  border: none;
  font-family: $neue-grotesk-medium-font;
  font-weight: 500;
  font-size: 16px;

  &:focus-visible {
    outline: 3px solid black;
    outline-offset: 3px;
  }

  &:disabled {
    opacity: 1;
    box-shadow: none;
    background-color: #f5f6f8;
    color: $content-secondary;

    img {
      filter: $filter-content-secondary;
    }
  }
}

.light {
  background-color: white;
  color: black;
  border: none;

  &.with_border {
    border: 1px solid #7d8ea7;
  }

  &:focus-visible {
    outline: 3px solid black;
    outline-offset: 3px;
  }
}

.transparent {
  color: $dark-blue-button-color;
  background-color: transparent;
  border: 1px solid $dark-blue-button-color;
}

.with_shadow {
  box-shadow: 0px 0px 15px 4px $shadow-color-opacity;
}

.stretchTo330px {
  min-width: 330px;
}

.IconButton {
  &.darkIcon {
    filter: invert(90%) sepia(94%) saturate(1821%) hue-rotate(124deg)
      brightness(109%) contrast(101%);
  }
}

.blur {
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  background-color: rgba(21, 21, 21, 0.35);
  border: 1px solid rgba(white, 0.35);
}

.expand {
  background-color: $lavender-web;
  border-radius: 10px;
  padding: 8px;
  border: none;
}

.underline {
  color: $dark-blue-button-color;
  background-color: transparent;
  text-decoration: underline;
}

.lightBlue {
  border: 2px solid $dark-blue-button-color;
  background: $light-blue;
  font-size: 16px;

  &:focus-visible {
    outline: 2px solid black;
    outline-offset: 3px;
  }
}

}