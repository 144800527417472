[widget-id='OVTwidget'] {
@import "../../../mixins/global";

.acuityTutorialStart {
  .acuityTutorialStartPage {
    padding-top: 1.875rem;
    width: 100%;
    max-width: unset;
  }

  .acuityTutorialStartBody {
    margin: auto;
    padding: 60px 24px;
    background: white;
    //flex: unset;
    border: 1px solid $border-color;
    border-radius: 40px;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    @include phone-and-tablet-portrait {
      max-width: calc(100vw - 48px);
      height: auto;
    }

    @include desktop-and-tablet-landscape {
      width: 100%;
      height: 100%;
    }
  }

  .acuityTutorialStartBodyContent {
    @include desktop-and-tablet-landscape {
      max-width: 446px;
    }

    opacity: 0;
    animation: 0.5s ease-in fadeIn forwards 0.5s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }

  .title {
    font-family: $poppins-medium-font;
    margin-bottom: 15px;
    font-weight: 500;
    line-height: 1.2;
    text-align: left;

    @include phone-and-tablet-portrait {
      font-size: 2rem;
      letter-spacing: -0.02rem;
    }

    @include desktop-and-tablet-landscape {
      font-size: 2.625rem;
      letter-spacing: -0.0265rem;
      text-align: center;
      margin-bottom: 10px;
    }
  }

  .description {
    font-family: $neue-grotesk-font;
    text-align: left;
    color: $content-secondary;
    font-weight: 500;
    line-height: 1.4;

    &:not(&:last-of-type) {
      margin-bottom: 10px;
    }

    b {
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
      color: $dark-blue-button-color;
    }

    @include phone-and-tablet-portrait {
      font-size: 1rem;
      letter-spacing: -0.0275rem;
    }

    @include desktop-and-tablet-landscape {
      font-size: 1.0625rem;
      letter-spacing: -0.0375rem;
      text-align: center;
    }
  }

  .footerRightButton {
    @include phone-and-tablet-portrait {
      width: 100%;
    }

    @include desktop-and-tablet-landscape {
      width: 9.375rem;
      height: 3rem;
    }
  }
}

}