[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.errorText {
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  color: $error;
  line-height: 1.3;

  &.marginTop {
    margin-top: 5px;
  }
}

}