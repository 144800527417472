[widget-id='OVTwidget'] {
@import "./mixins/global.scss";
@import "./fonts/fonts.scss";

&.OVTwidget {
  height: 100%;
  font-size: 16px;
  @include default-scrollbar;
  font-family: $poppins-font;
}

//DO NOT TOUCH html AND body OF THE ECOMMERCE CALLER
// html {
//   background: $background-gradient-color;
//   height: 100%;
//   font-size: 16px;
//   @include default-scrollbar;
// }

// body {
//   font-family: $poppins-font;
// }

// html,
// body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}

ol,
ul {
  list-style-position: outside;
  padding-left: 22px;
}

blockquote,
q {
  quotes: none;
}

blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

div,
section,
article {
  box-sizing: border-box;
}

.Icon.Close,
.Icon.Back {
  cursor: pointer;
}

h1,
h2,
h3,
h4,
p,
li {
  line-height: 1.5;
}

p,
span {
  &.blue {
    background-image: $text-background-gradient-color;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

button,
select,
input,
a,
label {
  -webkit-tap-highlight-color: transparent;
}

video::-webkit-media-controls,
video::-webkit-media-controls-start-playback-button,
video::-webkit-media-controls-play-button,
video::-webkit-media-controls-panel,
video::-webkit-media-controls-container,
video::-webkit-media-controls-overlay-play-button,
video::-webkit-media-controls-enclosure {
  display: none !important;
  -webkit-appearance: none;
  opacity: 0 !important;
  position: absolute !important;
  top: -99999px !important;
}

// *:focus {
//   background-color: red;
//   outline: 1px solid red;
// }

@include get-fonts("https://onlinevisiontest.luxottica.com/public/assets");

}