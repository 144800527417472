[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.SelectList {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.SelectListRadioButton {
  background-color: white;
}

input:checked ~ label,
.hiddenLabel {
  font-family: $neue-grotesk-bold-font;
  font-weight: bold;
}

.hiddenLabel {
  visibility: hidden;
  width: 0;
  height: 0;
}

}