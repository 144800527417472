// breakpoints for media query
$mininum-supported-breakpoint: 320px;
$breakpoints-xs: 350px;
$breakpoints-sm: 768px;
$breakpoints-md: 992px;
$breakpoints-lg: 1200px;

@mixin phone {
  @media (max-width: $breakpoints-sm - 1) and (orientation: portrait),
    (max-width: $breakpoints-md - 1) and (orientation: landscape) {
    @content;
  }
}

@mixin phone-portrait {
  @media (max-width: $breakpoints-sm - 1) and (orientation: portrait) {
    @content;
  }
}

@mixin phone-landscape {
  @media (max-width: $breakpoints-md - 1) and (orientation: landscape) {
    @content;
  }
}

@mixin overPhone {
  @media (min-width: $breakpoints-sm - 1) and (orientation: portrait),
    (min-width: $breakpoints-md - 1) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet {
  @media (min-width: $breakpoints-sm) and (max-width: $breakpoints-lg - 1) and (orientation: portrait),
    (min-width: $breakpoints-md) and (max-width: $breakpoints-lg - 1) and (orientation: landscape) {
    @content;
  }
}

@mixin tablet-portrait {
  @media (min-width: $breakpoints-sm) and (max-width: $breakpoints-lg - 1) and (orientation: portrait) {
    @content;
  }
}

@mixin tablet-landscape {
  @media (min-width: $breakpoints-md) and (max-width: $breakpoints-lg - 1) and (orientation: landscape) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: $breakpoints-lg - 0.5) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $breakpoints-lg) {
    @content;
  }
}

@mixin desktop-and-tablet-landscape {
  @media (min-width: $breakpoints-lg),
    (min-width: $breakpoints-md) and (max-width: $breakpoints-lg - 1) and (orientation: landscape) {
    @content;
  }
}

@mixin phone-and-tablet-portrait {
  @media (max-width: $breakpoints-md - 0.5),
    (min-width: $breakpoints-sm) and (max-width: $breakpoints-lg - 1) and (orientation: portrait) {
    @content;
  }
}

@mixin reduced-dropdown-menu-needed {
  @media (max-height: 816px) and (min-width: 816px) {
    @content;
  }
}
