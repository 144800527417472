[widget-id='OVTwidget'] {
@import "../../mixins/global";

.StartPage2 {
  .StartPage2Body {
    padding-top: 1.875rem;
    width: 100%;
    max-width: unset;

    @include phone-and-tablet-portrait {
      padding: 0;
    }
  }

  .separator {
    min-height: 0;
  }
}

.StartButton {
  @include phone-and-tablet-portrait {
    margin-bottom: 25px;
  }
}

.whiteBackgroundPage {
  @include phone-and-tablet-portrait {
    background: white;
  }
}

.creditCardImage {
  margin-top: 15px;
  max-width: 100%;
}

}