[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($dark-blue-button-color, 0.7);
  // mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  z-index: 500;
  height: var(--vh-nobars, 100vh);
  overflow-y: auto;
  padding: $desktop-padding;
  animation: fadeIn 0.2s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.fadingOut {
    animation: fadeOut 0.2s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  }

  @include phone-and-tablet-portrait {
    padding: $phone-padding;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: calc(756px + (80px * 2));
  height: 100%;
  background-color: white;
  padding: 0 16px 24px 16px;
  border-radius: 12px;
  overflow-y: hidden;
  max-height: 500px;

  @include phone-and-tablet-portrait {
    max-width: 100%;
  }
}

.titleRow {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
  position: relative;
  padding-top: 12px;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  background: white;
}

.htmlTitle {
  font-family: $poppins-medium-font;
  font-size: 18px;
  font-weight: 500;
  color: $dark-blue-button-color;
  text-align: center;
  max-width: 213px;
  padding: 0 24px;
  letter-spacing: -0.18px;
}

.closeIcon {
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  min-width: 0;
}

.topicsContainer,
.openedQuestionContainer {
  color: $dark-blue-button-color;
  overflow: auto visible;
  padding: 0 6px;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
}

.topicContainer {
  width: 100%;
  border-radius: 12px;
  padding: 12px;
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;

  border: solid 1px $border-color;
  font-family: $neue-grotesk-medium-font;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  background-color: transparent;

  &.isOpen {
    border: solid 1px black;
  }

  .topicTitleContainer {
    width: 100%;
    padding: 0 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $dark-blue-button-color;

    .topicContainerArrow {
      transform: rotate(90deg);

      &.isOpen {
        transform: rotate(-90deg);
      }
    }
  }
}

.openedQuestionTopic {
  font-weight: bold;
  font-size: 16px;
  margin-right: auto;
}

.openedQuestionTitle {
  font-weight: bold;
  font-size: 14px;
  margin-right: auto;
}

.openedQuestionAnswer {
  margin-right: auto;
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  color: $dark-blue-button-color;
  line-height: 1.5;

  a,
  a:active,
  a:focus,
  a:visited {
    text-decoration: underline;
    color: inherit;
  }
}

.goBackButton {
  margin-top: auto;
  margin-bottom: 10px;
  width: 170px;

  @include phone-and-tablet-portrait {
    width: 100%;
  }
}

.topicQuestionsContainer {
  width: 100%;
  border-radius: 12px;
  padding: 15px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;

  border: solid 1px $border-color;
  font-family: $neue-grotesk-medium-font;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  letter-spacing: 0px;
  color: $dark-blue-button-color;
  text-align: left;
  background-color: white;
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fadeOut {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

}