[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba($dark-blue-button-color, 0.9);
  // mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  z-index: 700;
  padding: $phone-padding;
}

.title {
  font-family: $poppins-medium-font;
  font-size: 31px;
  font-weight: 500;
  letter-spacing: -0.31px;
  text-align: center;
  color: #fff;
  max-width: 375px;
  margin-bottom: 15px;
}

.message {
  font-family: $poppins-medium-font;
  font-weight: 500;
  font-size: 18px;
  color: white;
  text-align: center;
  letter-spacing: -0.31px;
  max-width: 375px;
}

.iconCircleRing {
  width: 135px;
  height: 135px;
  margin-bottom: 15px;
}

.iconCircleCircle {
  width: 117px;
  height: 117px;
}

.rotateDeviceIcon {
  width: 64px;
  height: 64px;
}

}