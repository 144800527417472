[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.buttonSurvey {
  display: none;
  border-radius: 12px;
  border: 1px solid $dark-blue-button-color;
  background: white;
  padding: 12px;
  color: $content-secondary;
  cursor: pointer;
  width: 100%;

  @include desktop-and-tablet-landscape {
    max-width: 300px;
  }

  @include phone-and-tablet-portrait {
    min-width: 0;
    width: 100%;
    display: block;
  }

  &.showOnDesktop {
    display: block;
  }

  &:focus-visible {
    outline: 2px solid black;
    outline-offset: 3px;
    border-radius: 10px;
  }
}

.selected {
  border-color: $dark-blue-button-color;
  color: $dark-blue-button-color;

  .placeholderIcon {
    filter: $filter-dark-blue-button-color;
  }
}

.buttonSurveyRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.placeholderSection {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.placeholderIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  flex-shrink: 0;

  filter: $filter-content-secondary;
}

.placeholder {
  font-family: $neue-grotesk-font;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.48px;
  text-align: left;
  color: $dark-blue-button-color;
  @include ellipsis(1);
}

.addIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.formatLabel {
  margin-top: 4px;
  color: $content-secondary;
  font-family: $neue-grotesk-font;
  font-size: 12px;
  font-weight: 400;
  padding-left: 16px;
}

.error {
}

.selectedFileContainer {
  padding-top: 12px;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.none {
  display: none;
}

}