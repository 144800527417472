[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.RecapData {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 50px;
}

}