[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.textarea {
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  color: $dark-blue-button-color;
  padding: 8px 16px;
  border-radius: 12px;
  border: 1px solid $border-color2;
  background: white;
  width: 100%;
  max-width: 100%;
  min-width: 272px;
  min-height: 120px;
  box-sizing: border-box;

  &:focus,
  &:focus-visible {
    outline: 2px solid #6792f4;
  }

  &::placeholder {
    color: $content-secondary;
  }

  &::-webkit-resizer {
    display: none;
  }

  &.withValue {
    border-color: $dark-blue-button-color;
  }
}

.textareaContainer {
  position: relative;
  width: fit-content;
  max-width: 100%;
}

.grabber {
  position: absolute;
  bottom: 12px;
  right: 6px;
  width: 18px;
  height: 18px;
  pointer-events: none;
}

}