[widget-id='OVTwidget'] {
@import "../../../mixins/global";

.lazyVideoContainer {
  position: relative;
}

.video {
  width: 100%;
  height: auto;
  object-fit: cover;
}

.volumeButton {
  position: absolute;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  padding: 0;
}

.volumeIcon {
  width: 20px;
  height: 20px;
  filter: invert(1);
}

.replayButton {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  padding: 0;
}

.volumeButton,
.replayButton {
  border: solid 1px rgba(255, 255, 255, 0.35);
  background-color: rgba(32, 35, 61, 0.8);
}

.replayIcon {
  width: 20px;
  height: 20px;
  filter: invert(1);
}

}