[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.Footer {
  width: 100%;
  height: 88px;
  z-index: 1;
  padding: 20px 30px;
  background-color: $lavender-web;
  box-shadow: 0 -8px 16px -2px rgba(#1b242c, 0.12),
    0 -2px 2px -1px rgba(#1b242c, 0.04);
  display: flex;
  align-items: center;
  bottom: 0;
  transition: width 0.3s ease;

  @include phone-and-tablet-portrait {
    height: auto;
    padding: 16px $phone-padding 25px;
    background-color: #dae6f3;
    box-shadow: 0 -8px 16px -2px rgba(#1b242c, 0.12),
      0 -2px 2px -1px rgba(#1b242c, 0.04);
    flex-direction: column;
  }

  &.flexStart {
    @include overPhone {
      justify-content: flex-start;
    }
  }

  &.flexEnd {
    @include overPhone {
      justify-content: flex-end;
    }
  }

  &.flexCenter {
    @include overPhone {
      justify-content: center;
    }
  }

  &.flexSpaceBetween {
    @include overPhone {
      justify-content: space-between;
    }
  }

  &.shadow {
    box-shadow: 0px -8px 16px $shadow-color-opacity,
      0px -2px 2px rgba($shadow-color, 0.04) !important;
  }

  &.whiteBackground {
    background-color: white;
  }
}

.fixed {
  position: fixed;
}

}