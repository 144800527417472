[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.uploadButton {
  display: block;
  border-radius: 12px;
  border: 1px solid $border-color2;
  background: white;
  padding: 12px;
  color: $content-secondary;
  cursor: pointer;

  &:focus-visible {
    outline: 2px solid black;
    outline-offset: 3px;
  }
}

.selected {
  border-color: $dark-blue-button-color;
  color: $dark-blue-button-color;

  .placeholderIcon {
    filter: $filter-dark-blue-button-color;
  }
}

.uploadButtonRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.placeholderSection {
  display: flex;
  align-items: center;
  margin-right: 8px;
}

.placeholderIcon {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  flex-shrink: 0;

  filter: $filter-content-secondary;
}

.placeholder {
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  @include ellipsis(1);
}

.addIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
}

.formatLabel {
  margin-top: 4px;
  color: $content-secondary;
  font-family: $neue-grotesk-font;
  font-size: 12px;
  font-weight: 400;
  padding-left: 16px;
}

.error {
}

.selectedFileContainer {
  padding-top: 12px;
  width: 100%;
}

.deleteFileRow {
}

.deleteFileIcon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 5px;
}

.deleteFileButton {
  font-family: $neue-grotesk-medium-font;
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue-button-color;
  padding: 0;
  text-decoration: underline;
  border: none;
  min-height: 0;
  display: flex;
  align-items: center;
  margin-top: 15px;
}

}