[widget-id='OVTwidget'] {
@import "../../mixins/global";

.acuityTutorial {
  .acuityTutorialPage {
    background: white;
  }

  .footer {
    background-color: transparent;
    box-shadow: none;
    z-index: 150;
  }

  .footerLeftButton {
    flex-shrink: 0;
  }

  .footerRightButton {
    min-width: unset;
  }

  .videoContainer {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: calc(100vw - ($phone-padding * 2));
  }
}

}