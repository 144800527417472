[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.badgeButtonContainer {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  flex-shrink: 0;
  padding: 0 13px;
  gap: 15px;
  width: 100%;
  overflow-x: auto;
  scrollbar-width: thin;
  /* Per Mozilla Firefox */

  &::-webkit-scrollbar {
    display: none;
    /* Per WebKit (Chrome, Safari, etc.) */
  }

  @include desktop-and-tablet-landscape {
    padding: 0 25px;
  }

  .badgeButton {
    padding: 0.25rem 0.5rem;
    border: solid 1px #c8d6eb;
    border-radius: 12px;
    background-color: white;
    text-decoration: none;
    width: fit-content;
    min-width: auto;
    min-height: auto;
    transition: background-color 0.3s ease-in-out;
  }

  .badgeButtonSelected {
    background-color: #3e70d6;
    color: white;
    border-color: transparent;
    transition: background-color 0.3s ease-in-out;
  }
}

.howToReadPrescriptionModalContainer {
  width: 100%;
  height: inherit;
  background-color: #f3f7ff;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  overflow-y: auto;

  @include phone-and-tablet-portrait {
    grid-template-rows: 1fr 11fr 1fr;
    height: 100%;
  }

  @include desktop-and-tablet-landscape {
    grid-template-rows: 10fr 39fr 1fr;
  }

  .fieldDescription {
    display: flex;
    flex-direction: column;
    padding: 0 13px;
    overflow-y: auto;
    transition: opacity 0.3s ease-in-out;
    position: absolute;
    opacity: 0;

    &.isSelected {
      opacity: 1;
    }

    @include desktop-and-tablet-landscape {
      padding: 0 25px;
    }
  }

  .dottedIndex {
    padding-bottom: 25px;
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}

}