[widget-id='OVTwidget'] {
@import "../../mixins/global";
@import "../../mixins/acuity-variables";

.acuityTest {
  .acuityTestPage {
    background: white;
  }

  .progressBar {
    margin-top: 15px;
  }

  .landoltCRing {
    .orientationRightRight {
      transform: translate(-50%, -50%) rotate(0deg);
    }

    .orientationBottomRight {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    .orientationBottomBottom {
      transform: translate(-50%, -50%) rotate(90deg);
    }

    .orientationBottomLeft {
      transform: translate(-50%, -50%) rotate(135deg);
    }

    .orientationLeftLeft {
      transform: translate(-50%, -50%) rotate(180deg);
    }

    .orientationTopLeft {
      transform: translate(-50%, -50%) rotate(-135deg);
    }

    .orientationTopTop {
      transform: translate(-50%, -50%) rotate(-90deg);
    }

    .orientationTopRight {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }

  $dot-border: 10px;
  $dot-width: $dot-size + ($dot-border * 2);
  $dot-width-expanded: $dot-size-expanded + ($dot-border * 2);

  .dots {
    @include on-circle(8, $landlont-ring-size, $dot-width);
    position: absolute;
  }

  .dot {
    width: $dot-width;
    height: $dot-width;
    border: $dot-border solid white;
  }

  .dotExpand {
    animation: 1s ease-in expandDot 3;
  }

  @keyframes expandDot {
    0% {
      width: $dot-width;
      height: $dot-width;
    }

    50% {
      margin: -($dot-width-expanded / 2);
      width: $dot-width-expanded;
      height: $dot-width-expanded;
    }

    100% {
      width: $dot-width;
      height: $dot-width;
    }
  }

  .dotExpandHighlighted {
    background-color: $dot-blue;
    border: none;
    animation: 1s ease-in expandDotHighlighted 3;
    width: $dot-highlighted-size;
    height: $dot-highlighted-size;
    margin: -($dot-highlighted-size / 2);
  }

  @keyframes expandDotHighlighted {
    0% {
      margin: -($dot-highlighted-size / 2);
      width: $dot-highlighted-size;
      height: $dot-highlighted-size;
    }

    50% {
      margin: -($dot-highlighted-size-expanded / 2);
      width: $dot-highlighted-size-expanded;
      height: $dot-highlighted-size-expanded;
    }

    100% {
      margin: -($dot-highlighted-size / 2);
      width: $dot-highlighted-size;
      height: $dot-highlighted-size;
    }
  }
}

.footer {
  background-color: transparent;
  box-shadow: none;
  z-index: 150;
}

}