[widget-id='OVTwidget'] {
@import "../../mixins/global";

$dot-size: 19px;
$c-size: var(--cSize, 70px);
$landlont-ring-size: 295px;
$tooltip-tail-height: 8px;

$dot-blue: #3e70d6;

.landoltCRing {
  width: $landlont-ring-size;
  height: $landlont-ring-size;
}

.landoltCRingBottom {
  display: none;
}

.landoltC {
  width: $c-size;
  height: $c-size;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  transform-origin: center center;
  display: flex;
  align-items: center;
  box-sizing: content-box;

  svg {
    width: 100%;
    height: 100%;
  }
}

.dots {
  @include on-circle(8, $landlont-ring-size, $dot-size);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.dot {
  width: $dot-size;
  height: $dot-size;
  border-radius: 50%;
  background: $content-secondary;
}

}