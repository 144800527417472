[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.tooltip {
  position: absolute;
  color: white;
  font-family: $neue-grotesk-font;
  font-size: 16px;
  font-weight: 400;
  z-index: 10;
}

.tooltipContent {
  padding: 15px;
  background-color: $dark-blue-button-color;
  filter: drop-shadow(0px 4px 25px #242e4426);
  border-radius: 8px;
  width: fit-content;
  margin: 0 auto;
}

$tooltip-tail-size: 8px;

.tooltipTail {
  position: absolute;
  width: 0;
  height: 0;
}

.footerTailTop {
  border-left: $tooltip-tail-size solid transparent;
  border-right: $tooltip-tail-size solid transparent;
  border-bottom: $tooltip-tail-size solid $dark-blue-button-color;
  top: -$tooltip-tail-size;
  left: 50%;
  transform: translateX(-50%);
}

.footerTailBottom {
  border-left: $tooltip-tail-size solid transparent;
  border-right: $tooltip-tail-size solid transparent;
  border-top: $tooltip-tail-size solid $dark-blue-button-color;
  bottom: calc(-#{$tooltip-tail-size} + 1px);
  left: 50%;
  transform: translateX(-50%);
}

.footerTailLeft {
  border-top: $tooltip-tail-size solid transparent;
  border-bottom: $tooltip-tail-size solid transparent;
  border-right: $tooltip-tail-size solid $dark-blue-button-color;
  top: 50%;
  left: -$tooltip-tail-size;
  transform: translateY(-50%);
}

.footerTailRight {
  border-top: $tooltip-tail-size solid transparent;
  border-bottom: $tooltip-tail-size solid transparent;
  border-left: $tooltip-tail-size solid $dark-blue-button-color;
  top: 50%;
  right: -$tooltip-tail-size;
  transform: translateY(-50%);
}

}