[widget-id='OVTwidget'] {
@import "../../mixins/global";

.Background {
  .title {
    font-family: $poppins-medium-font;
    font-size: 24px;
    font-weight: 500;
    color: $dark-blue-button-color;
    margin-bottom: 10px;

    &:focus {
      outline: none;
    }
  }

  .description {
    font-family: $neue-grotesk-font;
    font-size: 16px;
    font-weight: 400;
    color: $dark-blue-button-color;
    margin-bottom: 32px;
    margin-bottom: 15px;

    b {
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
    }
  }

  .buttonsContainer {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 24px;
    gap: 24px;

    @include phone-and-tablet-portrait {
      width: 100%;
      flex-direction: column-reverse;
      margin-top: 40px;
    }
  }

  .shopGlassesButton {
    width: 100%;

    @include desktop-and-tablet-landscape {
      width: unset;
      margin-left: 15px;
      flex-shrink: 0;
    }

    @include phone-and-tablet-portrait {
      margin-top: 15px;
    }
  }

  .footer {
    @include phone-and-tablet-portrait {
      display: block;
    }
  }
}

.pageBody {
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding-left: 14.625rem;
  padding-right: 14.625rem;
  padding-top: 5rem;
  border-radius: 36px;
  background: white;
  height: 100%;

  @include phone-and-tablet-portrait {
    padding: 0;
    border-radius: 36px 36px 0px 0px;
  }
}

}