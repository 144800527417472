[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.SurveySteps {
  height: 100%;
  border-radius: 34px 0 0 34px;
  width: 233px;
  padding: 20px 20px 0 0;
  background: $border-color;
  float: left;

  background-color: rgba(white, 0.4);
  border: 1px solid $border-color;
  border-right: none;
  backdrop-filter: blur(5px);
}

.SurveyStepsContent {
  width: 100%;
  opacity: 0;
  animation: 0.8s linear fadeIn forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.hiddenRightSection {
  position: absolute;
  right: -27px;
  height: 100%;
  width: 27px;
  top: 0;
  z-index: -1;
  background-color: rgba(white, 0.4);
  border-top: 1px solid $border-color;
  border-bottom: 1px solid $border-color;
  backdrop-filter: blur(5px);
}

.rightTransaction {
  position: absolute;
  right: -27px;
  top: 0;
  background-color: white;
  height: 100%;
  z-index: 1;
  animation: 0.8s linear reduceWidth forwards;
  border-radius: 36px 0 0 36px;

  @keyframes reduceWidth {
    0% {
      width: calc(233px + 27px);
    }

    100% {
      width: 0;
    }
  }
}

.TitleAndSteps {
  width: 100%;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 5px;
  font-family: $poppins-font;
  font-size: 14px;
  font-weight: 600;
  line-height: 22px;
}

.StepRecap {
  font-family: $neue-grotesk-font;
  font-size: 12px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.1px;
  text-align: center;

  span {
    background-image: $text-background-gradient-color;
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
  }
}

}