[widget-id='OVTwidget'] {
@import "../../mixins/global";
@import "../../mixins/acuity-variables";

$tooltip-tail-height: 8px;

.acuityTutorial {
  background: white;

  .acuityTutorialPage {
    background: white;
    height: var(--vh-nobars, 100vh);
    position: relative;
    overflow: hidden;
  }

  .landoltContainer {
    margin: 0 auto;
  }

  .blurLayer {
    position: absolute;
    width: 100vw;
    top: 0;
    left: 0;
    height: 100vh;
    z-index: 20;
  }

  .landoltC {
    transform: translate(-50%, -50%) rotate(45deg);
  }

  .landoltCRingBottom {
    display: none;
  }

  .footer {
    background-color: transparent;
    box-shadow: none;
    z-index: 150;
  }

  .footerLeftButton {
    flex-shrink: 0;
  }

  .footerRightButton {
    min-width: unset;
  }

  .tooltip {
    width: 100%;
    max-width: calc(100vw - (#{$phone-padding} * 2));
    z-index: 50;
  }

  &.acuityTutorialStep0 {
    .landoltCRingBottom,
    .landoltCRingUpper,
    .blurLayer {
      display: none;
    }

    .videoContainer {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
    }
  }

  //STEP1
  //acuity tutorial animations here --> https://www.figma.com/proto/x1NGIHLlmvQ1F1WGGNF2ne/branch/7svqGp0xEPiAdB0qUDiagm/RX-Renewal_Essilor_2023?type=design&page-id=734%3A17899&node-id=3590-8484&viewport=503%2C-209%2C0.13&t=7jfa3YuOYYwmhcPO-1&scaling=scale-down&starting-point-node-id=3590%3A8484&show-proto-sidebar=1
  //initially
  //  no footer
  //  show the C without other dots
  //  the background is white
  //after 2 seconds
  //  show footer
  //  change background color and show a circle around the C
  //  show the tooltip "Look at the orientation of the C"
  &.acuityTutorialStep1 {
    $c-border: 30px;

    .footer {
      opacity: 0;
      animation: 0.5s ease-in fadeIn1;
      animation-fill-mode: forwards;
      animation-delay: 2s;

      @keyframes fadeIn1 {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    .blurLayer {
      animation: 0.5s ease-in backgroundBlur;
      animation-fill-mode: forwards;
      animation-delay: 2s;
    }

    .landoltC {
      z-index: 50;
      border-radius: 50%;
      border: $c-border solid white;
      background-color: white;
    }

    .dots {
      opacity: 0;
    }

    .step1Tooltip {
      opacity: 0;
      animation: 0.5s ease-in step1TooltipSlideBottom;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      left: 50%;
      transform: translate(-50%, -100%);

      @keyframes step1TooltipSlideBottom {
        0% {
          opacity: 1;
          top: calc(
            50% - (#{$c-size} / 2) - #{$c-border} - #{$tooltip-tail-height} -
              28px
          );
        }

        100% {
          opacity: 1;
          top: calc(
            50% - (#{$c-size} / 2) - #{$c-border} - #{$tooltip-tail-height} -
              8px
          );
        }
      }
    }
  }

  //STEP2
  //initially
  //  no footer
  //  show the C with dots
  //  the background is white
  //after 2 seconds
  //  show footer
  //  change background color and show the dots around the C, the C is under blur effect
  //  show the tooltip "There are 8 dots around the C."
  &.acuityTutorialStep2 {
    $dot-border: 10px;

    .footer {
      opacity: 0;
      animation: 0.5s ease-in fadeIn;
      animation-fill-mode: forwards;
      animation-delay: 2s;
    }

    .blurLayer {
      animation: 0.5s ease-in backgroundBlur;
      animation-fill-mode: forwards;
      animation-delay: 2s;
    }

    .dots {
      @include on-circle(8, $landlont-ring-size, $dot-size + ($dot-border * 2));
      position: absolute;
      z-index: 50;

      .dot {
        width: $dot-size;
        height: $dot-size;
        box-sizing: content-box;
        border: $dot-border solid white;
      }
    }

    .step2Tooltip {
      opacity: 0;
      animation: 0.5s ease-in step2TooltipSlideBottom;
      animation-fill-mode: forwards;
      animation-delay: 2s;
      left: 50%;
      transform: translate(-50%, -100%);

      @keyframes step2TooltipSlideBottom {
        0% {
          opacity: 1;
          top: calc(
            50% - (#{$landlont-ring-size} / 2) - (#{$dot-size} / 2) - #{$dot-border} -
              #{$tooltip-tail-height} - 28px
          );
        }

        100% {
          opacity: 1;
          top: calc(
            50% - (#{$landlont-ring-size} / 2) - (#{$dot-size} / 2) - #{$dot-border} -
              #{$tooltip-tail-height} - 8px
          );
        }
      }
    }
  }

  //STEP3
  //initially
  //  no footer
  //  show only the dot at bottom-right with a white border
  //  the background is blur
  //after 0.5 seconds
  //  show footer
  //  show the tooltip "Click on the dot facing the fap in the C."
  &.acuityTutorialStep3 {
    // $dot-border: 46px;
    $c-border: 15px;
    $dot-border: calc((#{$c-size} + (#{$c-border} * 2) - #{$dot-size}) / 2);

    .footer {
      opacity: 0;
      animation: 0.5s ease-in fadeIn3;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;

      .footerRightButton {
        opacity: 0;
      }
    }

    @keyframes fadeIn3 {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }

    .blurLayer {
      background-color: rgba($dark-blue-button-color, 0.58);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px); //for safari
    }

    .landoltCRingBottom {
      display: block;
    }

    .landoltCRingUpper {
      .dots {
        bottom: calc(#{$dot-size} + (#{$dot-border} * 2));
        @include on-circle(
          8,
          $landlont-ring-size,
          calc(#{$dot-size} + (#{$dot-border} * 2))
        );
        position: absolute;
        z-index: 50;

        .dot {
          opacity: 0;
          width: $dot-size;
          height: $dot-size;
          box-sizing: content-box;
          border: $dot-border solid white;

          &.dotBottomRight {
            opacity: 1;
          }
        }
      }
    }

    .step3Tooltip {
      opacity: 0;
      animation: 0.5s ease-in step3TooltipSlideBottom;
      animation-fill-mode: forwards;
      animation-delay: 0.5s;

      transform: rotate(45deg) translate(calc($landlont-ring-size / 2))
        rotate(-45deg)
        translate(
          calc(-100% + (#{$dot-size} / 2) + #{$dot-border}),
          calc(
            -100% - (#{$dot-size} / 2) - #{$dot-border} - #{$tooltip-tail-height}
          )
        );
      left: 50%;

      .step3TooltipContent {
        margin: 0 0 0 auto;
      }

      .step3TooltipTail {
        left: calc(100% - (#{$dot-size} / 2) - #{$dot-border});
      }

      @keyframes step3TooltipSlideBottom {
        0% {
          opacity: 1;
          top: calc(50% - 28px);
        }

        100% {
          opacity: 1;
          top: calc(50% - 8px);
        }
      }
    }
  }

  //STEP4
  //initially
  //  starting from previous step
  //after 0.5 second
  //  the bottom right dot expand its size and turns blue
  //after 1.5 seconds
  //  the C is no more blurred
  //  a white background appears between the C and the bottom right dot
  //after 2 seconds
  //  3 blue dots appear between the C and the bottom right dot
  //after 4 seconds
  //  show the footer
  //  show the tooltip "This is correct."
  &.acuityTutorialStep4 {
    $c-border: 15px;
    // $dot-border: 46px;
    $dot-border: calc((#{$c-size} + (#{$c-border} * 2) - #{$dot-size}) / 2);
    // $dot-highlighted-border: 31px;
    $dot-highlighted-border: calc(
      (#{$c-size} + (#{$c-border} * 2) - #{$dot-highlighted-size}) / 2
    );
    $step4-background-height: calc(#{$dot-size} + (#{$dot-border} * 2));

    .footer {
      opacity: 0;
      animation: 0.5s ease-in fadeIn;
      animation-fill-mode: forwards;
      animation-delay: 4s;
    }

    .blurLayer {
      background-color: rgba($dark-blue-button-color, 0.58);
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px); //for safari
    }

    .landoltCRingBottom {
      display: block;
    }

    .landoltCRingUpper {
      .dots {
        @include on-circle(8, $landlont-ring-size, $dot-size);
        position: absolute;
        z-index: 50;

        .dot {
          opacity: 0;
          width: $dot-size;
          height: $dot-size;
          box-sizing: content-box;
          // border: $dot-border solid white;

          &.dotBottomRight {
            opacity: 1;
            animation: 1s ease-in expandDotStep4;
            animation-fill-mode: forwards;
            animation-delay: 0.5s;

            @keyframes expandDotStep4 {
              0% {
                width: $dot-size;
                height: $dot-size;
                // border: $dot-border solid white;
              }

              100% {
                width: $dot-highlighted-size;
                height: $dot-highlighted-size;
                margin: -($dot-highlighted-size / 2);
                // border: $dot-highlighted-border solid white;
                background-color: $dot-blue;
              }
            }
          }
        }
      }

      .landoltC {
        opacity: 0;
        z-index: 50;
        animation: 0.5s ease-in fadeIn;
        animation-fill-mode: forwards;
        animation-delay: 1.5s;
      }
    }

    .step4dotBorder {
      position: absolute;
      z-index: 40;
      transform: rotate(45deg) translate(calc($landlont-ring-size / 2))
        rotate(-45deg) translate(-50%, -50%);
      left: 50%;
      top: 50%;
      width: $step4-background-height;
      height: $step4-background-height;
      border-radius: 50%;
      background-color: white;
    }

    .step4Background {
      opacity: 0;
      position: absolute;
      z-index: 40;
      animation: 0.5s ease-in fadeIn;
      animation-fill-mode: forwards;
      animation-delay: 1.5s;
      background-color: white;

      width: calc(
        (
            (
                #{$landlont-ring-size+20px} + #{$c-size} + #{$dot-highlighted-border} +
                  #{$dot-highlighted-size}
              ) / 2
          ) + #{$c-border}
      );
      height: calc((#{$dot-highlighted-border} * 2) + #{$dot-highlighted-size});
      top: calc(50% - ($step4-background-height / 2));
      left: calc(50% - ($step4-background-height / 2));

      border-radius: calc(
        ((#{$dot-highlighted-border} * 2) + #{$dot-highlighted-size}) / 2
      );
      transform: rotate(45deg);
      transform-origin: calc($step4-background-height / 2) center;
    }

    .step4DotBlue {
      position: absolute;
      z-index: 60;
      background-color: $dot-blue;
      opacity: 0;
      top: 50%;
      left: 50%;
      border-radius: 50%;
      opacity: 0;
    }

    $distance-c-dot: calc(
      (#{$landlont-ring-size} / 2) - (#{$dot-highlighted-size} / 2) - #{$dot-highlighted-border}
    );

    .step4DotBlue1 {
      width: 11px;
      height: 11px;
      animation: 0.5s ease-in fadeIn04;
      animation-delay: 2s;
      transform: rotate(45deg) translate(calc(#{$distance-c-dot} / 3 + 15px))
        rotate(-45deg);
      animation-fill-mode: forwards;

      @keyframes fadeIn04 {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 0.4;
        }
      }
    }

    .step4DotBlue2 {
      width: 15px;
      height: 15px;
      animation: 0.5s ease-in fadeIn06;
      animation-delay: 2.5s;
      transform: rotate(45deg)
        translate(calc(#{$distance-c-dot} / 3 + 15px + 11px + 8px))
        rotate(-45deg);
      animation-fill-mode: forwards;

      @keyframes fadeIn06 {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 0.6;
        }
      }
    }

    .step4DotBlue3 {
      width: 21px;
      height: 21px;
      animation: 0.5s ease-in fadeIn08;
      animation-delay: 3s;
      transform: rotate(45deg)
        translate(calc(#{$distance-c-dot} / 3 + 15px + 11px + 8px + 15px + 8px))
        rotate(-45deg);
      animation-fill-mode: forwards;

      @keyframes fadeIn08 {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 0.8;
        }
      }
    }

    .step4Tooltip {
      opacity: 0;
      animation: 0.5s ease-in step4TooltipSlideBottom;
      animation-fill-mode: forwards;
      animation-delay: 4s;
      left: 50%;
      transform: translate(-50%, -100%);

      @keyframes step4TooltipSlideBottom {
        0% {
          opacity: 1;
          top: calc(
            50% - (#{$step4-background-height} / 2) - #{$tooltip-tail-height} - 28px
          );
        }

        100% {
          opacity: 1;
          top: calc(
            50% - (#{$step4-background-height} / 2) - #{$tooltip-tail-height} - 8px
          );
        }
      }
    }
  }

  //STEP5
  //initially
  //  no footer
  //  background white
  //  dots are blinking
  //after 4 second
  //  show the footer
  //  background is blurred
  //  show the tooltip "The dots are blinking; you have only a few seconds left to change your mind!"
  &.acuityTutorialStep5 {
    .footer {
      opacity: 0;
      animation: 0.5s ease-in fadeIn5;
      animation-fill-mode: forwards;
      animation-delay: 4.5s;

      @keyframes fadeIn5 {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    .blurLayer {
      animation: 0.5s ease-in backgroundBlur;
      animation-fill-mode: forwards;
      animation-delay: 4.5s;
    }

    .landoltCRingBottom {
      display: block;

      .dots {
        .dot {
          width: $dot-size;
          height: $dot-size;
          box-sizing: content-box;
          animation: 2s ease-in expandDotStep5 2;
          animation-delay: 0.5s;

          @keyframes expandDotStep5 {
            0% {
              opacity: 1;
              width: $dot-size;
              height: $dot-size;
            }

            50% {
              margin: -($dot-size-expanded / 2);
              width: $dot-size-expanded;
              height: $dot-size-expanded;
            }

            100% {
              width: $dot-size;
              height: $dot-size;
            }
          }
        }
      }
    }

    .landoltCRingUpper {
      .dots {
        @include on-circle(8, $landlont-ring-size, $dot-highlighted-size);
        position: absolute;
        z-index: 50;

        .dot {
          opacity: 0;

          &.dotBottomRight {
            opacity: 1;
            animation: 2s linear expandDotBottomRight5 2,
              0s linear showBorder forwards;
            animation-delay: 0.5s, 4.5s;
            background-color: $dot-blue;

            @keyframes expandDotBottomRight5 {
              0% {
                width: $dot-highlighted-size;
                height: $dot-highlighted-size;
              }

              50% {
                margin: -($dot-highlighted-size-expanded / 2);
                width: $dot-highlighted-size-expanded;
                height: $dot-highlighted-size-expanded;
              }

              100% {
                width: $dot-highlighted-size;
                height: $dot-highlighted-size;
              }
            }

            @keyframes showBorder {
              100% {
                box-sizing: content-box;
                width: $dot-highlighted-size;
                height: $dot-highlighted-size;
                border: $dot-highlighted-border solid white;
                margin: calc(
                  0px - (($dot-highlighted-size / 2) + $dot-highlighted-border)
                );
              }
            }
          }
        }
      }
    }

    .step5Tooltip {
      opacity: 0;
      animation: 0.5s ease-in step5TooltipSlideBottom forwards;
      animation-delay: 4.5s;

      transform: rotate(45deg) translate(calc($landlont-ring-size / 2))
        rotate(-45deg)
        translate(
          calc(
            -100% + (#{$dot-highlighted-size} / 2) + #{$dot-highlighted-border}
          ),
          calc(
            -100% - (#{$dot-highlighted-size} / 2) - #{$dot-highlighted-border} -
              #{$tooltip-tail-height}
          )
        );
      left: 50%;

      .step5TooltipContent {
        margin: 0 0 0 auto;
      }

      .step5TooltipTail {
        left: calc(
          100% - (#{$dot-highlighted-size} / 2) - #{$dot-highlighted-border}
        );
      }

      @keyframes step5TooltipSlideBottom {
        0% {
          opacity: 1;
          top: calc(50% - 28px);
        }

        100% {
          opacity: 1;
          top: calc(50% - 8px);
        }
      }
    }
  }

  //STEP6
  //initially
  //  no footer
  //  background white
  //  dots are blinking
  //after 6 second
  //  the entire content fade out
  &.acuityTutorialStep6 {
    .footer {
      opacity: 0;
    }

    .landoltContainer {
      animation: 0.5s ease-in fadeOut forwards;
      animation-delay: 6.5s;

      @keyframes fadeOut {
        0% {
          opacity: 1;
        }

        100% {
          opacity: 0;
        }
      }
    }

    .landoltCRingBottom {
      display: block;

      .dots {
        .dot {
          width: $dot-size;
          height: $dot-size;
          box-sizing: content-box;
          animation: 2s ease-in expandDotStep6 3;
          animation-delay: 0.5s;

          @keyframes expandDotStep6 {
            0% {
              opacity: 1;
              width: $dot-size;
              height: $dot-size;
            }

            50% {
              margin: -($dot-size-expanded / 2);
              width: $dot-size-expanded;
              height: $dot-size-expanded;
            }

            100% {
              width: $dot-size;
              height: $dot-size;
            }
          }
        }
      }
    }

    .landoltCRingUpper {
      .dots {
        @include on-circle(8, $landlont-ring-size, $dot-highlighted-size);
        position: absolute;
        z-index: 50;

        .dot {
          opacity: 0;

          &.dotBottomRight {
            opacity: 1;
            animation: 2s linear expandDotBottomRight6 3;
            animation-delay: 0.5s;
            background-color: $dot-blue;

            @keyframes expandDotBottomRight6 {
              0% {
                width: $dot-highlighted-size;
                height: $dot-highlighted-size;
              }

              50% {
                margin: -($dot-highlighted-size-expanded / 2);
                width: $dot-highlighted-size-expanded;
                height: $dot-highlighted-size-expanded;
              }

              100% {
                width: $dot-highlighted-size;
                height: $dot-highlighted-size;
              }
            }
          }
        }
      }
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes backgroundBlur {
  0% {
    background-color: rgba($dark-blue-button-color, 0);
    backdrop-filter: blur(0px);
    -webkit-backdrop-filter: blur(0px); //for safari
  }

  100% {
    background-color: rgba($dark-blue-button-color, 0.58);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px); //for safari
  }
}

}