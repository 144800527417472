[widget-id='OVTwidget'] {
@import "../../mixins/global";

.acuityWrong.pageCardMobile {
  .overPageContainer {
    margin-bottom: 10px;
  }

  .radioContainer {
    margin-top: 25px;
  }

  .description {
    font-family: $neue-grotesk-font;
    font-weight: 400;
    font-size: 14px;

    b {
      color: $dark-blue-button-color;
      font-family: $neue-grotesk-medium-font;
      font-size: 16px;
      font-weight: 500;
    }
  }

  .radioButton {
    font-family: $neue-grotesk-medium-font;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 20px;

    label {
      margin-left: 8px;
    }
  }

  .footer {
    padding-bottom: 5px;
  }
}

}