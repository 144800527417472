[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($dark-blue-button-color, 0.9);
  // mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  z-index: 500;
  max-height: 100vh;
  overflow-y: auto;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: calc(756px + (80px * 2));
  padding: 50px $desktop-padding 50px;
  max-height: 100vh;
  overflow-y: auto;

  @include phone-and-tablet-portrait {
    padding: 100px $phone-padding 200px;
    max-width: 100%;
  }
}

.checkIcon {
  width: 74px;
  height: 50px;
}

.exclamationIcon {
  width: 10px;
  height: 91px;
}

.message {
  color: white;
  text-align: center;
  font-family: $poppins-font;
  font-size: 31px;
  font-weight: 500;
  margin-top: 25px;
  line-height: 1.2;
  letter-spacing: -0.31px;
  max-width: 500px;
}

.reverse {
  transform: scale(-1, 1);
}

.description {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-family: $poppins-medium-font;
  font-size: 18px;
  font-weight: 500;
  max-width: 500px;

  br {
    content: "";
    display: block;
    height: 15px;
  }
}

.subdescription {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-family: $neue-grotesk-font;
  font-weight: 400;
  font-size: 16px;

  br {
    content: "";
    display: block;
    height: 15px;
  }
}

.CloseIcon {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;

  img {
    filter: invert(1);
  }
}

.cta {
  margin: 0 auto;

  @include phone-and-tablet-portrait {
    width: 100%;
    min-width: unset;
  }
}

.ctaDark {
  border: solid 1px $content-secondary;
}

.qrCodeSection {
  background-color: $base-primary-lightest;
  border-radius: 10px;
  padding: 16px;
  position: relative;
  width: 446px;
  height: 301px;
  margin-top: 1.5625rem;

  .qrCodeBody {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    .qrCodeContainer {
      .img {
        width: 152px;
        height: 152px;
      }
    }

    .qrCodeDescrContainer {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin: 1.5625rem 2.5rem 1.875rem 2.5rem;

      .qrCodeDescr {
        font-family: $poppins-font;
        font-size: 18px;
        font-weight: 500;
        max-width: 19.6875rem;
        line-height: 1.2;
        letter-spacing: -0.18px;
        text-align: center;
      }
    }
  }

  .qrCodeExpandButton {
    position: absolute;
    top: 0.5rem;
    right: 0;
  }
}

.modalQrCodeBody {
  background-color: $base-primary-lightest;
  padding: 16px;
  border-radius: 10px;
  position: relative;
  width: 42.5rem;
  height: 41.5625rem;
  max-height: 100vh;

  .modalQrCodeContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 90%;

    .img {
      width: 20.8125rem;
      height: 20.8125rem;
    }
  }

  .modalQrCodeDescr {
    font-family: $neue-grotesk-font;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 16px;
    color: #131524;
    margin: 1.5625rem 11rem 0 11rem;
    text-align: center;
  }

  .modalQrCodeCompressButton {
    position: absolute;
    top: 0.5rem;
    right: 0;
  }
}

.loader {
  width: 185px;
  height: 185px;
}

.ctaContainer {
  width: 100%;
  padding: 8px 0;
  display: flex;
  flex-direction: column;
  gap: 14px;
  margin-top: 25px;
}

}