[widget-id='OVTwidget'] {
@import "../../mixins/global";

.setupCalibrateScreen {
  .none {
    display: none !important;
  }

  .selectionContainer {
    margin: 27px 0 32px;
    display: flex;
    flex-direction: column;
    gap: 24px;
  }
}

}