[widget-id='OVTwidget'] {
@import "../../mixins/global";

.errorModal {
  .errorModalBody {
    padding-bottom: 160px;
  }
}

.fallbackTitle {
  font-family: $poppins-medium-font;
  font-size: 26px;
  font-weight: 500;
  margin: 24px 0;
}

.errorPopup {
  position: fixed;
  top: 8px;
  left: 50%;
  width: 100%;
  max-width: calc(100vw - 16px);
  width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($dark-blue-button-color, 0.9);
  mix-blend-mode: multiply;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px); //for safari
  z-index: 800;
  padding: 24px;
  border-radius: 10px;
  transform: translateX(-50%);

  opacity: 0;
  animation: 0.5s ease-in fadeIn;
  animation-fill-mode: forwards;

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
}

.errorPopupTitle {
  font-family: $poppins-medium-font;
  font-size: 16px;
  font-weight: 500;
  color: white;
  text-align: center;
}

.cannotGoBackPopup {
  z-index: 805 !important;
}

}