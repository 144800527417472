[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  z-index: 600;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: $phone-padding;
  width: 100%;
}

.message {
  color: $dark-blue-button-color;
  text-align: center;
  font-family: $poppins-medium-font;
  font-size: 300px;
  font-weight: 500;
  margin-top: 25px;
}

.reverse {
  transform: scale(-1, 1);
}

}