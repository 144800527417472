[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.container {
  display: block;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: flex-start;

  font-family: $neue-grotesk-bold-font;
  font-size: 14px;
  font-weight: bold;
  color: $dark-blue-button-color;

  input {
    // display: none;
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;

    &:focus-visible ~ .checkmark {
      outline: 2px solid black;
      outline-offset: 3px;
    }

    &:checked ~ .checkmark {
      background-color: $dark-blue-button-color;
      border-color: $dark-blue-button-color;
    }

    &:disabled ~ .checkmark.showMinusIconOnDisable {
      background-color: $dark-blue-button-color;
      border-color: $dark-blue-button-color;
    }
  }
}

.checkmark {
  display: block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  background-color: white;
  border: 2px solid $border-color2;
  margin: auto 8px auto 0;
  flex-shrink: 0;
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;

  img {
    width: 100%;
    height: auto;
  }
}

}