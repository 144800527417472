//browsers mixins
@import "./viewports.scss";
@import "./browsers.scss";

/////////////
// ONLINE VISION TEST
/////////////
//fonts
$poppins-font: "Poppins";
$poppins-medium-font: "Poppins-Medium";

$neue-grotesk-font: "NeueHaasGrotesk-Regular";
$neue-grotesk-medium-font: "NeueHaasGrotesk-Medium";
$neue-grotesk-bold-font: "NeueHaasGrotesk-Bold";

// colors
$dark-blue-button-color: #20233d;
$fluorescent-blue-text-button-color: #20fffa;
$solid-05: #7386b3;
$tint-05-dark: #4b4d6e;
$tint-05-dark-blurred: #5c6b82;
$error: #c60c0c;
$base-primary-lightest: #f3f6fc;
$base-other-color-1: #adbed8;
$shadow-color: #131245;
$shadow-color-opacity: #13124526;
$lavender-web: #e4eaf5;
$black-primary: #222222;
$black-transparent-button: #001526;
$content-secondary: #5c6b82;
$border-color: #d9e4f5;
$border-color2: #8396b4;
$light-blue: #edf3fd;

$filter-dark-blue-button-color: invert(10%) sepia(38%) saturate(905%)
  hue-rotate(196deg) brightness(102%) contrast(92%);

$background-gradient-color: linear-gradient(
  176.69deg,
  #edf7f0 -83.47%,
  #9caeff 515.08%
);
$text-background-gradient-color: linear-gradient(
  90deg,
  #1f62af -10.69%,
  #0d8ba6 122.5%
);
$menu-section-background-gradient-color: linear-gradient(
  231.22deg,
  #f4e1ef -17.3%,
  #87bce1 115.47%
);

$filter-tint-05-dark: invert(28%) sepia(48%) saturate(340%) hue-rotate(197deg)
  brightness(92%) contrast(88%);
$error-filter: invert(15%) sepia(100%) saturate(3702%) hue-rotate(355deg)
  brightness(82%) contrast(99%);
$filter-content-secondary: brightness(0) saturate(100%) invert(44%) sepia(9%)
  saturate(1084%) hue-rotate(177deg) brightness(90%) contrast(90%);

$input-shadow: 0px 5.890908718109131px 11.781817436218262px 0px
    rgba(#606170, 0.16),
  0px 0px 2.9454543590545654px 0px rgba(#28293d, 0.4);
$button-shadow: 0px 5.890908718109131px 11.781817436218262px 0px
  rgba(#606170, 0.16);

//page padding
$desktop-padding: 30px;
$phone-padding: 24px;

//default scrollbar style
$scrollbar-thumb: $content-secondary;
$scrollbar-track: $border-color;

@mixin default-scrollbar {
  scroll-behavior: smooth;

  //hide default scrollbar for IE
  -ms-overflow-style: none;

  //for Firefox (but it seems not working)
  scrollbar-width: 4px;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-track {
    background: $scrollbar-track;
    border-radius: 3px;
  }

  ::-webkit-scrollbar-thumb {
    background: $scrollbar-thumb;
    border-radius: 3px;
    cursor: pointer;
  }
}

//mixin to use a border with a gradient
@mixin border-gradient(
  $border-gradient-radius,
  $border-gradient,
  $border-gradient-background
) {
  border: none;
  position: relative;

  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: $border-gradient-radius;
    border: $border-gradient;
    background: $border-gradient-background;
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: source-out;
    -webkit-mask-composite: source-out;

    @include firefox {
      mask-composite: exclude;
      -webkit-mask-composite: destination-out;
    }
  }
}

//mixin to implement ellipsis
@mixin ellipsis($rows) {
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: $rows;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

/// Mixin to place items on a circle
/// @author Kitty Giraudel
/// @author Ana Tudor
/// @param {Integer} $item-count - Number of items on the circle
/// @param {Length} $circle-size - Large circle size
/// @param {Length} $item-size - Single item size
@mixin on-circle($item-count, $circle-size, $item-size) {
  position: relative;
  width: $circle-size;
  height: $circle-size;
  padding: 0;
  border-radius: 50%;
  list-style: none;

  > * {
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    width: $item-size;
    height: $item-size;
    margin: calc(0px - (#{$item-size} / 2));

    $angle: (360 / $item-count);
    $rot: 0;

    @for $i from 1 through $item-count {
      &:nth-of-type(#{$i}) {
        transform: rotate($rot * 1deg)
          translate($circle-size / 2)
          rotate($rot * -1deg);
      }

      $rot: $rot + $angle;
    }
  }
}
