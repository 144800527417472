[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.ring {
  width: 213px;
  height: 213px;
  border-radius: 50%;
  border: 2px solid rgba(white, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;

  &.inAcuityTestFlow {
    width: 150px;
    height: 150px;
  }
}

.circle {
  width: 185px;
  height: 185px;
  border-radius: 50%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &.inAcuityTestFlow {
    width: 122px;
    height: 122px;
  }
}

.checkIcon {
  width: 74px;
  height: 50px;
}

.exclamationIcon {
  width: 10px;
  height: 91px;
}

}