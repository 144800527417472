[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.chip {
  border-radius: 12px;
  padding: 4px 12px;
  background-color: $content-secondary;
  color: white;
  font-family: $neue-grotesk-medium-font;
  font-weight: 500;
  font-size: 14px;
  width: fit-content;
  max-width: 100%;
  overflow-wrap: break-word;

  &.noWrap {
    .label {
      @include ellipsis(1);
    }
  }
}

}