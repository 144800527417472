[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.RecapElement {
  height: fit-content;
  width: 100%;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  padding: 12px;
  border: solid 1px black;
  background-color: white;
}

.RecapElementTitle {
  width: 100%;
  font-family: $neue-grotesk-font;
  display: flex;
  gap: 5px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.48px;
  text-align: left;
  color: black;
}

.EditLink {
  margin-left: auto;
  background-color: transparent;
  border: none;
}

.answersContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.AnswerLabel {
  align-items: center;
  width: fit-content;
  padding: 4px 12px;
  border-radius: 12px;
  background-color: $content-secondary;

  font-family: $neue-grotesk-font;
  color: white;
  font-size: 14px;
  font-weight: 500;
  line-height: 1.4;
  letter-spacing: -0.42px;
}

}