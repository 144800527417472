[widget-id='OVTwidget'] {
@import "../../mixins/global";

.pageCardMobile {
  .overPageContainer {
    margin: 10px auto 13px;
    color: $dark-blue-button-color;
    text-align: center;

    @include desktop-and-tablet-landscape {
      display: none;
    }
  }

  .overPageTitle {
    font-family: $neue-grotesk-medium-font;
    font-size: 14px;
    font-weight: 500;
  }

  .overPageDescription {
    font-family: $neue-grotesk-font;
    font-size: 12px;
    font-weight: 400;

    .blue {
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
    }

    i {
      font-style: italic;
    }
  }

  .overPageSteps {
    color: $dark-blue-button-color;
    font-family: $neue-grotesk-font;
    font-size: 12px;
    font-weight: 400;

    .blue {
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
    }
  }

  .pageCardMobileBody {
    padding: 0;

    @include desktop-and-tablet-landscape {
      padding: 30px $desktop-padding 118px;
      max-width: unset;
    }
  }

  .pageBodyCard {
    border-radius: 36px 36px 0px 0px;
    background: white;
    padding: 20px 24px 120px;
    height: 100%;
    width: 100%;

    @include desktop-and-tablet-landscape {
      border-radius: 36px;
      padding: 60px;
      padding: 0;
      background-color: transparent;
    }

    &.noPaddingBodyCard {
      @include desktop-and-tablet-landscape {
        padding: 0;
      }
    }
  }

  .pageBody {
    @include desktop-and-tablet-landscape {
      max-width: 768px;
      margin: 0 auto;
    }
  }

  .title {
    font-family: $poppins-medium-font;
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.2;

    &:focus {
      outline: none;
    }

    @include desktop-and-tablet-landscape {
      font-size: 2.3125rem;
    }
  }

  .description {
    font-family: $neue-grotesk-font;
    font-size: 16px;
    font-weight: 400;
    color: $content-secondary;
    margin-top: 10px;

    @include desktop-and-tablet-landscape {
      font-size: 17px;
      margin-bottom: 60px;
    }

    b {
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
      color: $dark-blue-button-color;
    }
  }

  .footerBackgroundWhite {
    background-color: white;
    box-shadow: 0px -8px 16px $shadow-color-opacity,
      0px -2px 2px rgba($shadow-color, 0.04);
    //padding-bottom: 15px;
  }

  .footerLeftButton {
    flex-shrink: 0;
  }

  .footerRightButton {
    @include phone-and-tablet-portrait {
      min-width: unset;
    }
  }
}

}