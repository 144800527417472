[widget-id='OVTwidget'] {
.DottedIndexContainer {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: row;
  gap: 0.625rem;

  .dottetIndexStep {
    width: 0.625rem;
    height: 0.625rem;
    border-radius: 50%;
    border: solid 1px #5c6b82;
    transition: background-color 0.3s ease-in-out;
  }

  .indexActive {
    background: #79a3f7;
  }
}

}