[widget-id='OVTwidget'] {
@import "../../../mixins/global";

.OrSeparator {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  @include desktop-and-tablet-landscape {
    min-height: 48px;
  }

  @include phone-and-tablet-portrait {
    width: 100%;
  }

  hr {
    display: none;

    @include phone-and-tablet-portrait {
      display: block;
      width: 100%;
      border: none;
      background: $lavender-web;
      height: 1px;
      margin: 0;
    }
  }
}

}