[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.spinner {
  display: inline-block;
  position: relative;
  width: 75px;
  height: 75px;
}

}