[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.prescriptionButtonsContainer {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100%;

  @include desktop-and-tablet-landscape {
    width: 330px;
    gap: 25px;
  }
}

.SelectPrescriptionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  .SelectPrescriptionController {
    width: 100%;
    display: flex;
    flex-direction: column;

    .Label,
    .inputLabel {
      margin-bottom: 10px;
      font-family: $poppins-medium-font;
      font-size: 14px;
      font-weight: 500;
    }

    .eyeSelectionLabel {
      width: 100%;
      text-align: left;
      margin-bottom: 10px;
    }

    .LabelsCol,
    .InputCol {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      flex: 1;
      margin-bottom: 15px;
      flex-direction: row;
      gap: 15px;
    }

    .InputField {
      background: white;
      border-radius: 10px;
      width: 100%;
    }
  }

  .ErrorContentDiv {
    color: $error;
    font-family: $poppins-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }

  .TwoPDsCheckbox {
    display: flex;
    font-family: $poppins-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 160%;

    &.DontKnowMyPDCase {
      opacity: 0.5;
      pointer-events: none;
    }
  }
}

.SelectPDMobileButtonContainer {
  width: 100%;

  .SelectPDMobileButton {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: $tint-05-dark;
    font-family: $poppins-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }
}

.howToReadPrescriptionLink {
  margin: 16px auto 0;
}

.isHowToReadPrescriptionOpen {
  .dropdownArrow {
    transform: rotate(180deg);
  }
}

.none {
  display: none !important;
}

.samePrescriptionCheckbox {
  padding: 5px 0;
  margin-bottom: 22px;
}

.PrismValuesCheckbox {
  margin: 5px 0 20px;
}

.errorBanner {
  margin: 0 0 20px 0;
}

.CLModalContainer {
  .CLModalContent {
    display: flex;
    flex-direction: column;
    gap: 20px;

    .ClBrandAndModelDescription {
      font: $neue-grotesk-font;
      font-weight: 500;
      color: $dark-blue-button-color;
    }

    .ClBrandAndModelInputs {
      display: flex;
      flex-direction: column;
      margin-bottom: 30px;

      .CLBrandInput,
      .CLModelInput {
        margin-top: 20px;
      }

      .CLBrandError,
      .CLModelError {
        display: flex;
        align-items: center;
        gap: 3px;
        margin-top: 5px;
        margin-left: 5px;

        font-family: $neue-grotesk-font;
        font-size: 12px;
        line-height: 1.5;
        letter-spacing: 0.1px;
        text-align: left;
        color: #ba1d1d;
      }
    }
  }
}

.hide {
  display: none;
}

}