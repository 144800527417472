[widget-id='OVTwidget'] {
@import "../../../mixins/global";

.Background {
  background: $background-gradient-color;
  position: relative;
  display: flex;
  width: 100%;
  min-height: var(--vh-nobars, 100vh);
  max-height: 100%;
  overflow: visible;

  .rightButton {
    min-width: 150px;

    @include phone-and-tablet-portrait {
      width: 100%;
      min-width: unset;
    }
  }
}

.backgroundWithVideo {
  background: unset;

  .Page {
    background: transparent;
  }

  .footer {
    background-color: transparent;
    box-shadow: none;
    //display: block;

    &.footerBackgroundWhite {
      background-color: white;
    }
  }
}

.footer {
  &.footerBackgroundWhite {
    background-color: white;
  }
}

.footerButtonsRow {
  display: flex;
  gap: 10px;
  align-items: center;
  width: 100%;
  justify-content: space-between;

  &.justifyContentEnd {
    justify-content: end;
  }
}

.introVideo,
.backgroundVideo {
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.backgroundVideo {
  opacity: 0;
  animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
  to {
    opacity: 1;
  }
}

.Page {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-x: visible;
  overflow-y: auto;
  transition: width 0.3s ease;
  background: $background-gradient-color;

  @include phone-and-tablet-portrait {
    align-items: center;
    justify-content: flex-start;
  }

  &.Compress {
    width: 50%;
    transition: width 0.3s ease;

    @include phone-and-tablet-portrait {
      width: 100%;
    }
  }
}

.PageBody {
  padding: 102px $desktop-padding 118px;
  width: 100%;
  max-width: calc(756px + (80px * 2));
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-sizing: border-box;
  flex: 1;

  @include phone-and-tablet-portrait {
    padding: 10px $phone-padding 120px;
    width: 100%;
    margin-bottom: 0;
    min-height: fit-content;
  }
}

.spinner {
  margin: auto;
}

.rightButtonSection {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

}