@charset "UTF-8";

/// get fonts from the right environment
/// it is called by webpack in webpack.config.js to get the base path
/// @param {String} $base-path - base path to get the fonts
@mixin get-fonts($base-path) {
  /* Poppins */
  @font-face {
    font-family: "Poppins";
    src: url("#{$base-path}/fonts/Poppins/Poppins-Regular.eot"); /* IE9 Compat Modes */
    src: local(""),
      url("#{$base-path}/fonts/Poppins/Poppins-Regular.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */ url("#{$base-path}/fonts/Poppins/Poppins-Regular.woff2")
        format("woff2"),
      /* Super Modern Browsers */
        url("#{$base-path}/fonts/Poppins/Poppins-Regular.woff") format("woff"),
      /* Modern Browsers */
        url("#{$base-path}/fonts/Poppins/Poppins-Regular.ttf")
        format("truetype"),
      /* Safari, Android, iOS */
        url("#{$base-path}/fonts/Poppins/Poppins-Regular.svg") format("svg"); /* Legacy iOS */
  }

  /* Poppins Medium */
  @font-face {
    font-family: "Poppins-Medium";
    src: url("#{$base-path}/fonts/Poppins/Poppins-Medium.eot");
    /* IE9 Compat Modes */
    src: local(""),
      url("#{$base-path}/fonts/Poppins/Poppins-Medium.eot?#iefix")
        format("embedded-opentype"),
      /* IE6-IE8 */ url("#{$base-path}/fonts/Poppins/Poppins-Medium.woff2")
        format("woff2"),
      /* Super Modern Browsers */
        url("#{$base-path}/fonts/Poppins/Poppins-Medium.woff") format("woff"),
      /* Modern Browsers */
        url("#{$base-path}/fonts/Poppins/Poppins-Medium.ttf") format("truetype"),
      /* Safari, Android, iOS */
        url("#{$base-path}/fonts/Poppins/Poppins-Medium.svg") format("svg");
    /* Legacy iOS */
  }

  /* Neue Haas Grotesk - Regular */
  @font-face {
    font-family: "NeueHaasGrotesk-Regular";
    /* IE9 Compat Modes */
    src: url("#{$base-path}/fonts/NeueHaas/grotesk/regular/NHaasGroteskTXPro-55Rg.eot");
    src: local(""),
      /* IE6-IE8 */
        url("#{$base-path}/fonts/NeueHaas/grotesk/regular/NHaasGroteskTXPro-55Rg.eot?#iefix")
        format("embedded-opentype"),
      /* Super Modern Browsers */
        url("#{$base-path}/fonts/NeueHaas/grotesk/regular/NHaasGroteskTXPro-55Rg.woff2")
        format("woff2"),
      /* Modern Browsers */
        url("#{$base-path}/fonts/NeueHaas/grotesk/regular/NHaasGroteskTXPro-55Rg.woff")
        format("woff"),
      /* Safari, Android, iOS */
        url("#{$base-path}/fonts/NeueHaas/grotesk/regular/NHaasGroteskTXPro-55Rg.ttf")
        format("truetype"),
      /* Legacy iOS */
        url("#{$base-path}/fonts/NeueHaas/grotesk/regular/NHaasGroteskTXPro-55Rg.svg")
        format("svg");
  }

  /* Neue Haas Grotesk - Medium */
  @font-face {
    font-family: "NeueHaasGrotesk-Medium";
    /* IE9 Compat Modes */
    src: url("#{$base-path}/fonts/NeueHaas/grotesk/medium/NHaasGroteskTXPro-65Md.eot");
    src: local(""),
      /* IE6-IE8 */
        url("#{$base-path}/fonts/NeueHaas/grotesk/medium/NHaasGroteskTXPro-65Md.eot?#iefix")
        format("embedded-opentype"),
      /* Super Modern Browsers */
        url("#{$base-path}/fonts/NeueHaas/grotesk/medium/NHaasGroteskTXPro-65Md.woff2")
        format("woff2"),
      /* Modern Browsers */
        url("#{$base-path}/fonts/NeueHaas/grotesk/medium/NHaasGroteskTXPro-65Md.woff")
        format("woff"),
      /* Safari, Android, iOS */
        url("#{$base-path}/fonts/NeueHaas/grotesk/medium/NHaasGroteskTXPro-65Md.ttf")
        format("truetype"),
      /* Legacy iOS */
        url("#{$base-path}/fonts/NeueHaas/grotesk/medium/NHaasGroteskTXPro-65Md.svg")
        format("svg");
  }

  /* Neue Haas Grotesk - Bold */
  @font-face {
    font-family: "NeueHaasGrotesk-Bold";
    /* IE9 Compat Modes */
    src: url("#{$base-path}/fonts/NeueHaas/grotesk/bold/NHaasGroteskTXPro-75Bd.eot");
    src: local(""),
      /* IE6-IE8 */
        url("#{$base-path}/fonts/NeueHaas/grotesk/bold/NHaasGroteskTXPro-75Bd.eot?#iefix")
        format("embedded-opentype"),
      /* Super Modern Browsers */
        url("#{$base-path}/fonts/NeueHaas/grotesk/bold/NHaasGroteskTXPro-75Bd.woff2")
        format("woff2"),
      /* Modern Browsers */
        url("#{$base-path}/fonts/NeueHaas/grotesk/bold/NHaasGroteskTXPro-75Bd.woff")
        format("woff"),
      /* Safari, Android, iOS */
        url("#{$base-path}/fonts/NeueHaas/grotesk/bold/NHaasGroteskTXPro-75Bd.ttf")
        format("truetype"),
      /* Legacy iOS */
        url("#{$base-path}/fonts/NeueHaas/grotesk/bold/NHaasGroteskTXPro-75Bd.svg")
        format("svg");
  }
}
