[widget-id='OVTwidget'] {
@import "../../mixins/global";

.footerInlineButtonLabel {
  font-family: $neue-grotesk-medium-font;
  font-size: 16px;
  font-weight: 500;
  color: $dark-blue-button-color;
  margin-top: 15px;
  text-align: center;
  padding: 0;
  width: 100%;
  text-decoration: underline;
  border: none;
  min-height: 0;
}

.AcuityEndPage {
  .AcuityEndPageContent {
    background: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;

    .title {
      font-family: $poppins-medium-font;
      font-size: 42px;
      font-weight: 500;
      margin-bottom: 10px;
      width: 27.875rem;
    }

    .description {
      font-family: $neue-grotesk-font;
      font-size: 16px;
      font-weight: 400;
      margin: 0;
      width: 27.875rem;
    }
  }

  .leftButton {
    font-family: $neue-grotesk-medium-font;
    font-weight: 500;
    font-size: 18px;
    width: 180px;
  }
}

}