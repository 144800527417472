[widget-id='OVTwidget'] {
@import "../../mixins/global";

$text-opacity-transition-duration: 0.75s;
$text-transition-duration: 1.5s;

.StartPage1 {
  @include desktop-and-tablet-landscape {
    overflow-y: hidden;
  }

  .StartPage1Body {
    padding-top: 20px;

    .StartPage1BodyContent {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      gap: 0.625rem;
    }

    @include desktop-and-tablet-landscape {
      margin-bottom: 0px;
      padding-bottom: 0;
    }

    @include phone-and-tablet-portrait {
      padding-left: 15px;
      padding-right: 15px;
    }

    .StartPage1HeaderContent {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: 0.625rem;

      opacity: 0;
      transform: translateY(-10%);
      transition: opacity $text-opacity-transition-duration ease-out,
        transform $text-transition-duration ease-out;

      &.show {
        opacity: 1;
        transform: translateY(0);
      }
    }

    .HiUserMessage {
      border-radius: 2rem;
      border: 0.0625rem solid $border-color;
      background: rgba(255, 255, 255, 0.33);
      padding: 0.375rem 0.9375rem;
      justify-content: center;
      align-items: center;
      text-align: center;

      font-family: $neue-grotesk-medium-font;
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
      line-height: 140%;
      letter-spacing: -0.48px;

      display: inline-block;

      @include phone-and-tablet-portrait {
        font-size: 14px;
        letter-spacing: -0.42px;
      }
    }

    .MyPrescriptionRenewalTitle {
      font-family: $poppins-medium-font;
      font-size: 42px;
      font-weight: 500;
      letter-spacing: -0.42px;
      line-height: 120%;
      text-align: center;

      @include phone-and-tablet-portrait {
        font-size: 34px;
        letter-spacing: -0.34px;
      }
    }

    .MyPrescriptionRenewalContent {
      font-family: $neue-grotesk-medium-font;
      font-size: 18px;
      font-weight: 500;
      line-height: 120%;
      letter-spacing: -0.18px;
      text-align: center;
      color: $content-secondary;

      opacity: 0;
      transform: translateY(+10%);
      transition: opacity $text-opacity-transition-duration ease-out,
        transform $text-transition-duration ease-out;

      @include phone-and-tablet-portrait {
        font-size: 16px;
        line-height: 140%;
        letter-spacing: -0.48px;
      }

      &.show {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}

.MyPrescriptionRenewalButton {
  margin-top: 1.875rem;
  margin-bottom: 114px;

  opacity: 0;
  transform: translateY(+20%);
  transition: opacity $text-opacity-transition-duration ease-out,
    transform $text-transition-duration ease-out;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  @include phone-and-tablet-portrait {
    width: 100%;
  }
}

.fakeCard {
  position: absolute;
  top: 100%;

  opacity: 0;
  transform: translateY(+20%);
  transition: opacity $text-opacity-transition-duration ease-out,
    transform $text-transition-duration ease-out;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  .fakeCard2 {
    position: relative;
    margin-left: -40px;

    .StartPage2Preview {
      position: relative;
      margin-left: -40px;
      margin-right: -40px;
    }
  }
}

.fakeCard,
.fakeCard2 {
  width: calc(100% + 80px);
  background-color: white;
  border: 1px solid $border-color;
  border-radius: 40px;
  padding: 15px 24px 0;
}

.BeforeYouStartPopUp {
  background: $dark-blue-button-color;
  position: fixed;
  bottom: 0;
  padding: 15px;
  width: 100%;
  z-index: 2;

  display: flex;
  opacity: 0;
  transform: translateY(+30%);
  transition: opacity $text-opacity-transition-duration ease-out,
    transform $text-transition-duration ease-out;

  &.show {
    opacity: 1;
    transform: translateY(0);
  }

  @include phone-and-tablet-portrait {
    flex-direction: column;
  }

  @include desktop-and-tablet-landscape {
    flex-direction: row;
  }

  .BeforeYouStartContent {
    width: 100%;
    padding: 16px;
    border-radius: 10px;
    margin-top: auto;

    font-family: $neue-grotesk-font;
    font-size: 14px;
    font-weight: 400;
    line-height: 140%;
    letter-spacing: -0.42px;
    color: white;
    text-align: center;

    a {
      text-decoration: underline;
      cursor: pointer;
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
    }

    @include phone-and-tablet-portrait {
      background: none;
      padding: 0;
      margin-bottom: 0;
    }

    @include desktop-and-tablet-landscape {
      br {
        display: none;
      }
    }
  }

  .BeforeYouStartPopUpButtonContainer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

}