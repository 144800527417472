[widget-id='OVTwidget'] {
@import "../../mixins/global";

.Background.pageCardMobile {
  .overPageContainer {
    margin-bottom: 10px;
  }
}

.pageBodyCard {
  padding: 0;
}

.PageBody {
  display: flex;
  justify-content: center;
  border-radius: 36px;
  background: white;
  height: 100%;

  @include phone-and-tablet-portrait {
    padding: 0;
    border-radius: 36px 36px 0px 0px;
    justify-content: center;
  }

  .SurveyStepContent {
    padding: 60px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: auto;
    max-width: 680px;

    @include desktop-and-tablet-landscape {
      min-width: 680px;
      z-index: 3;
      opacity: 0;
      animation: 0.5s ease-in fadeIn forwards 0.8s;

      @keyframes fadeIn {
        0% {
          opacity: 0;
        }

        100% {
          opacity: 1;
        }
      }
    }

    @media screen and (max-width: 768px) {
      padding: 30px 0;
    }

    @include phone-and-tablet-portrait {
      padding: 0;
      width: 100%;
    }

    &.recapSurveyStepContent {
      z-index: unset;
    }

    .StepFormContainer {
      width: 100%;
      margin-top: 25px;

      .StepForm {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        @include phone-and-tablet-portrait {
          gap: 15px;
        }

        &.ColumnDirectionForm {
          flex-direction: column;
          align-items: flex-start;
        }

        &.RowDirectionForm {
          flex-direction: row;
          align-items: flex-start;

          @include phone-and-tablet-portrait {
            flex-direction: column;
          }
        }
      }

      .HowToReadPrescriptionContainer {
        margin: 1.5625rem 0;
      }
    }

    .description {
      @include desktop-and-tablet-landscape {
        margin-bottom: 0;
      }
    }
  }
}

.spinner {
  margin: auto;
}

.closeButton {
  margin-left: auto;
}

.recapCheckbox {
  margin: 0 25px 0 0;

  @include phone-and-tablet-portrait {
    margin: 0 0 22px 0;
  }
}

}