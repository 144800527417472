[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.radioInsideSelect {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin-right: 20px;
  z-index: 0;

  &.radioInsideSelectHidden {
    display: none;
  }

  input {
    width: 16px;
    height: 16px;
    margin: 0;
  }
}

.selectImage {
  width: 24px;
  height: 24px;
  margin-right: 12px;
  filter: $filter-tint-05-dark;
  flex-shrink: 0;
}

.optionContainer {
  display: block;

  &:last-of-type {
    .option {
      margin-bottom: 0px;
    }
  }
}

.optionSeparator {
  height: 1px;
  width: 100%;
  background-color: $lavender-web;
  margin: 8px 0;
}

.option {
  display: flex;
  align-items: flex-start;
}

.optionRadio {
  flex-shrink: 0;

  input {
    width: 16px;
    height: 16px;
    margin: 2px 12px 0 0;
  }
}

.optionCheckbox {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  margin: 2px 12px 0 3px;
}

.optionImageContainer {
  width: 64px;
  margin-right: 12px;
  position: relative;
}

.optionImage {
  position: absolute;
  top: -5px;
  width: 64px;
  height: 35px;
}

.optionTextContainer {
  color: $tint-05-dark;
}

.optionTitle {
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.42px;
  text-align: left;
  color: $dark-blue-button-color;
}

.invisibleLabel {
  display: none;
}

.optionDescription {
  margin-top: 5px;
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.42px;
  text-align: left;
  color: $content-secondary;
}

.selectContainer {
  height: 48px;
  position: relative;
  background-color: transparent;
  border: none;
  padding: 0;

  @include desktop-and-tablet-landscape {
    width: 300px;
  }

  @include phone-and-tablet-portrait {
    width: 100%;
    height: 100%;
  }

  &.selectContainerWithValueSelected {
    .selectControl {
      border-radius: 10px 10px 0 0;
      border: 2px solid black;
      border-bottom: none !important;

      &:hover {
        border: 2px solid black;
      }
    }
  }

  &.isInsideModal {
    .selectControl {
      display: none;
    }

    .selectMenuList {
      margin: 0;
      padding: 0;
    }

    .selectMenu {
      max-height: 100%;
      position: relative;
      border-radius: 0;
      background-color: transparent;
    }

    .selectOption {
      background-color: transparent;
    }

    .selectInput {
      line-height: 1;
      @include ellipsis(2);
    }
  }

  &.selectError,
  &.selectErrorWithMessage {
    &:not(.selectContainerWithValueSelected) {
      .selectControl {
        border-color: $error;

        &::before {
          background: $error;
        }
      }

      .selectedElementsContainer {
        @include border-gradient(10px 10px 0 0, 2px solid transparent, $error);

        &::before {
          border-bottom: 0;
        }
      }
    }
  }

  .selectControl {
    display: flex;
    align-items: center;
    justify-content: space-between;

    cursor: pointer;
    padding: 11.2px 16px;
    min-height: 24px;
    border-radius: 10px;
    border: solid 1px $dark-blue-button-color;
    position: relative;
    z-index: 4;

    &.topShadow {
      box-shadow: 0px 16px 16px -16px $shadow-color-opacity,
        0px 2px 2px -2px rgba($shadow-color, 0.04);
    }

    .selectControlLeft {
      display: flex;
      align-items: center;
      min-width: 0;
    }
  }

  .selectIndicator {
    display: flex;
    align-items: center;

    img {
      width: 20px;
      height: 20px;
      filter: $filter-tint-05-dark;
      flex-shrink: 0;

      &.closeIcon {
        width: 15px;
        height: 15px;
      }
    }
  }

  .selectOpen {
    border-radius: 10px 10px 0 0;
    border: solid 2px $dark-blue-button-color;
    border-bottom: none;

    &:hover {
      border: solid 2px $dark-blue-button-color;
      border-bottom: none;
    }

    .selectInput {
      color: $dark-blue-button-color;
      touch-action: none;

      &::placeholder {
        color: $dark-blue-button-color;
      }
    }

    .selectIndicator img {
      filter: $filter-dark-blue-button-color;
    }
  }

  .selectInput {
    appearance: none;
    -webkit-appearance: none;
    border: none;
    margin: 0;
    padding: 0;
    min-height: 24px;
    font-family: $poppins-medium-font;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.5;
    color: $tint-05-dark;
    cursor: pointer;
    min-width: 0;

    &::placeholder {
      color: $tint-05-dark;
    }

    &:focus {
      outline: none;
    }
  }

  .selectMenu {
    position: absolute;
    width: 100%;
    background-color: #fff;
    margin-top: 0;
    @include default-scrollbar;
    top: calc(100% - 10px);
    padding-top: 10px;
    border: solid 2px black;
    border-top: none;
    border-radius: 0 0 10px 10px;
    max-height: 396px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    z-index: 3;
    box-shadow: none;

    @include phone-and-tablet-portrait {
      max-height: 415px;
      border: none;
      box-shadow: none;
    }
  }

  .selectMenuList {
    padding: 14px 16px 24px 0;
    max-height: 100%;
    overflow-y: auto;
    position: relative;
    -webkit-overflow-scrolling: touch;
    @include reduced-dropdown-menu-needed {
      max-height: 200px;
    }

    &.bottomPosition {
      box-shadow: inset 0px 16px 16px -16px rgba(19, 18, 69, 0.1490196078),
        inset 0px 2px 2px -2px rgba(19, 18, 69, 0.04);
    }

    &.middlePosition {
      box-shadow: inset 0px 16px 16px -16px rgba(19, 18, 69, 0.1490196078),
        inset 0px 2px 2px -2px rgba(19, 18, 69, 0.04),
        inset 0px -16px 16px -16px rgba(19, 18, 69, 0.1490196078),
        inset 0px -2px 2px -2px rgba(19, 18, 69, 0.04);
    }

    &.topPosition {
      box-shadow: inset 0px -16px 16px -16px rgba(19, 18, 69, 0.1490196078),
        inset 0px -2px 2px -2px rgba(19, 18, 69, 0.04);
    }
  }

  .selectOption {
    border-radius: 10px;
    background-color: transparent;
    padding: 10px 0;
    margin: 0 8px 8px 16px;
    max-width: calc(100% - 24px);

    cursor: default;
    display: block;
    width: 100%;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    color: inherit;

    display: block;

    &:last-of-type {
      .option {
        margin-bottom: 0px;
      }
    }

    .option {
      display: flex;
    }
  }

  &:focus-visible {
    outline: 0;

    > div {
      outline: 2px solid black;
      outline-offset: 3px;
      border-radius: 10px;
    }
  }
}

.selectContainerWithValueSelected {
  :global {
    // .prefixSelect__control:not(.prefixSelect__control--menu-is-open) {
    //   @include border-gradient(10px 10px 0 0,
    //     2px solid transparent,
    //     $text-background-gradient-color );

    //   &::before {
    //     border-bottom: 0;
    //   }
    // }
    .prefixSelect__control {
      border-radius: 10px 10px 0 0;
      border: 2px solid black;
      border-bottom: none !important;

      &:hover {
        border: 2px solid black;
      }
    }
  }
}

.selectedElementsContainer {
  border-radius: 0 0 10px 10px;
  padding: 0 16px 12px;
  background-color: white;
  cursor: pointer;

  @include border-gradient(
    0 0 10px 10px,
    2px solid transparent,
    $dark-blue-button-color
  );

  @include phone-and-tablet-portrait {
    display: none;
  }

  &::before {
    border-top: none;
  }
}

.selectedElementsSeparator {
  padding: 0 16px;
  margin-bottom: 12px;
  background-color: white;
  width: 100%;
  height: 1px;
}

.selectedElement {
  display: flex;
  align-items: center;
  margin-top: 12px;

  + .errorMessageContainer {
    margin-top: 16px;
  }
}

.selectedElementArrow {
  width: 16px;
  height: 16px;
  margin: 0 14px 0 6px;
  flex-shrink: 0;
}

.selectedChip {
  color: white;
  background: $content-secondary;
  font-size: 14px;
  font-family: $neue-grotesk-font;
  font-weight: 400;
  line-height: 1.5;
  padding: 2.5px 8px;
  border-radius: 10px;
}

.errorMessageContainer {
  display: flex;
  align-items: flex-start;

  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  color: $error;
}

.errorMessageIcon {
  flex-shrink: 0;
  width: 16px;
  height: 16px;
  filter: $error-filter;
  margin: 0 14px 0 6px;
}

.menuSelectedSectionContainer {
  position: relative;
  flex-shrink: 0;
  padding: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 8px 16px;
  box-shadow: 0px -10px 20px 0px rgba(#28293d, 0.1);

  @include safari-14-or-less {
    gap: 0;
    margin-right: 16px;
    margin-bottom: 4px;
  }

  @include phone-and-tablet-portrait {
    display: none;
  }
}

.isInsideModal {
  :global {
    .prefixSelect {
      &__control {
        @include phone-and-tablet-portrait {
          padding-top: 5.8px;
          padding-bottom: 5.8px;
        }
      }

      &__menu {
        @include phone-and-tablet-portrait {
          max-height: 100%;
          position: relative;
          border-radius: 0;
        }
      }

      &__placeholder,
      &__input-container {
        line-height: 1;
        @include ellipsis(2);
      }
    }
  }
}

.ConfirmButtonContainer {
  padding: 10px;
  z-index: 1;

  &.bottomShadow {
    box-shadow: 0px -16px 16px -16px $shadow-color-opacity,
      0px -2px 2px -2px rgba($shadow-color, 0.04);
  }

  .ConfirmButton {
    width: 100%;
  }
}

.none {
  display: none;
}

}