[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($dark-blue-button-color, 0.9);
  // mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  z-index: 500;
  max-height: 100vh;
  overflow-y: auto;
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  max-width: calc(756px + (80px * 2));
  padding: 100px $desktop-padding 140px;
  max-height: 100vh;
  overflow-y: auto;

  @include phone-and-tablet-portrait {
    padding: 100px $phone-padding;
    max-width: 100%;
  }
}

.shrinkForSmallDevices {
  @media (max-height: 700px) {
    .message {
      margin-top: 0;
    }

    .iconCircleModal {
      display: none;
    }
  }
}

.modalReverse {
  .modalBody {
    padding-top: 50px;
    padding-bottom: 80px;
  }
}

.iconCircleModal {
}

.checkIcon {
  width: 74px;
  height: 50px;
}

.exclamationIcon {
  width: 10px;
  height: 91px;
}

.message {
  color: white;
  text-align: center;
  font-family: $poppins-medium-font;
  font-size: 31px;
  font-weight: 500;
  margin-top: 25px;

  &.inAcuityTestFlow {
    font-size: 56px;
  }
}

.reverse {
  transform: scale(-1, 1);
}

.description {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-family: $poppins-medium-font;
  font-size: 18px;
  font-weight: 500;

  br {
    content: "";
    display: block;
    height: 15px;
  }
}

.subdescription {
  margin-top: 15px;
  color: white;
  text-align: center;
  font-family: $neue-grotesk-font;
  font-weight: 400;
  font-size: 16px;

  br {
    content: "";
    display: block;
    height: 15px;
  }
}

.CloseIcon {
  position: absolute;
  z-index: 2;
  top: 15px;
  right: 15px;
  width: 40px;
  height: 40px;

  img {
    filter: invert(1);
  }
}

.cta {
  min-width: 330px;
  margin: 0 auto;

  @include phone-and-tablet-portrait {
    width: 100%;
    min-width: unset;
  }
}

.ctaDark {
  border: solid 1px $content-secondary;
}

}