[widget-id='OVTwidget'] {
@import "../../mixins/global";

.Background {
  .overPageContainer {
    margin-bottom: 10px;
  }

  .description {
    color: $dark-blue-button-color;
    font-family: $neue-grotesk-font;
    font-size: 17px;
    margin-top: 10px;

    b {
      color: $dark-blue-button-color;
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
    }

    @include phone-and-tablet-portrait {
      font-size: 16px;
    }
  }

  .infoBanner {
    margin-top: 25px;
  }

  .uploadPhoto {
    width: 50%;

    @include phone-and-tablet-portrait {
      margin: 25px 0 15px;
      width: 100%;
    }
  }

  .uploadDocument {
    width: 50%;

    @include phone-and-tablet-portrait {
      margin: 15px 0 25px;
      width: 100%;
    }
  }

  .uploadOrSeparator {
    @include desktop-and-tablet-landscape {
      height: 46.6px;
    }
  }

  .uploadSection {
    @include desktop-and-tablet-landscape {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 30px;
      margin-top: 25px;
      padding-bottom: 60px;
    }
  }
}

.pageBody {
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding-left: 14.625rem;
  padding-right: 14.625rem;
  padding-top: 5rem;
  border-radius: 36px;
  background: white;
  height: 100%;

  @include phone-and-tablet-portrait {
    padding: 0;
    border-radius: 36px 36px 0px 0px;
  }
}

.pageBodyContent {
  @include desktop-and-tablet-landscape {
    opacity: 0;
    animation: 0.5s ease-in fadeIn forwards 0.8s;

    @keyframes fadeIn {
      0% {
        opacity: 0;
      }

      100% {
        opacity: 1;
      }
    }
  }
}

}