[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.input {
  width: 100%;
  height: 48px;
  box-sizing: border-box;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid $solid-05;
  background: white;

  color: $tint-05-dark;
  font-family: $poppins-medium-font;
  font-size: 14px;
  font-weight: 500;
  appearance: none;
  outline: none;

  &:focus {
    border: 1px solid $dark-blue-button-color;
    box-shadow: 0 0 0 1px $dark-blue-button-color, $input-shadow;
  }

  &:focus ~ .floatingLabel,
  &.withValue ~ .floatingLabel {
    top: 0;
    left: 16px;
    font-size: 12px;
    letter-spacing: 0.1px;
    background-color: white;
    height: fit-content;
    padding: 0 4px;
  }
}

.container {
  position: relative;

  &.disabled {
    opacity: 0.5;
  }
}

.icon {
  position: absolute;
  top: 12px;
  left: 14px;

  width: 20px;
  height: 20px;
  filter: $filter-tint-05-dark;

  ~ input {
    padding-left: 40px;
  }

  ~ .floatingLabel {
    left: 40px;
  }
}

.error {
  // .icon {
  //   filter: $error-filter;
  // }

  .input {
    color: $error;
    border-color: $error;

    &:focus {
      border-color: $error;
      box-shadow: 0 0 0 1px $error, $input-shadow;
    }
  }
}

.floatingLabel {
  position: absolute;
  pointer-events: none;
  left: 16px;
  top: 50%;
  transform: translateY(-50%);
  transition: 0.2s ease all;
  font-family: $neue-grotesk-font;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: -0.42px;
  text-align: left;
  color: $content-secondary;
}

}