@mixin safari-14-or-less {
  @supports (-webkit-touch-callout: none) {
    @supports not (translate: none) {
      @content;
    }
  }
}

@mixin safari {
  @media not all and (min-resolution: 0.001dpcm) {
    @supports (-webkit-appearance: none) and (stroke-color: transparent) {
      @content;
    }
  }
}

@mixin not-safari {
  @media not (min-resolution: 0.001dpcm) {
    @supports (not (-webkit-appearance: none)) or
      (not (stroke-color: transparent)) {
      @content;
    }
  }
}

@mixin firefox {
  @-moz-document url-prefix() {
    @content;
  }
}
