[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.HowToReadPrescriptionModalLink {
  font-family: $neue-grotesk-medium-font;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.42px;
}

.howToReadPrescriptionModal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  background: rgba(32, 35, 61, 0.7);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  z-index: 700;

  @include phone-and-tablet-portrait {
    align-items: flex-end;
  }

  &.insideModal {
    position: absolute;
    bottom: 0;
    background: unset;
    backdrop-filter: unset;
    -webkit-backdrop-filter: unset;
    top: unset;
    max-height: 424px;

    .howToReadPrescriptionModalContent {
      width: 100%;
      height: 100%;
    }
  }
}

.howToReadPrescriptionModalContent {
  display: flex;
  flex-direction: column;
  background-color: #f3f7ff;
  align-items: center;
  border: 1px solid #79a3f7;
  overflow: hidden;

  @include desktop-and-tablet-landscape {
    position: relative;
    border-radius: 0.75rem;
    width: 35.1875rem;
    height: 36.875rem;
  }

  @include phone-and-tablet-portrait {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    // padding: 0 0.8125rem;
    width: 100%;
    height: 520px;
    max-height: 85%;
    overflow: hidden;
    border-radius: 15px 15px 0 0;
  }

  .closeIcon {
    position: absolute;
    width: 0.8125rem;
    height: 0.8125rem;
    min-width: auto;
    top: 1.5rem;
    right: 1.5rem;
    padding: 12px;
  }

  .howToReadPrescriptionModalContentTitle {
    width: 100%;
    text-align: center;

    font-family: $neue-grotesk-font;
    font-size: 24px;
    font-style: normal;
    font-weight: 500;
    line-height: 120%;
    letter-spacing: -0.24px;
    padding: 25px;

    @include phone-and-tablet-portrait {
      margin: 1.5625rem 0 1rem 0;
      padding: 0 1.8125rem;
    }
  }
}

}