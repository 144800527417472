[widget-id='OVTwidget'] {
@import "../../../mixins/global";

.banner {
  border-radius: 6px;
  border: 1px solid #79a3f7;
  background-color: #e7effe;
  display: flex;
  align-items: flex-start;
  padding: 20px;
  margin-top: 25px;

  @include desktop-and-tablet-landscape {
    margin-top: 0;
  }

  &.warning {
    border: solid 1px #ffb63b;
    background-color: #fff6e2;

    .text {
      color: #704300;
    }
  }

  &.error {
    border: solid 1px #f55959;
    background-color: #ffe7e7;

    .text {
      color: #530a0a;
    }
  }
}

.icon {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  margin-right: 16px;
}

.text {
  color: #253c6b;
  font-family: $neue-grotesk-medium-font;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: -0.42px;
}

.description {
  font-family: $neue-grotesk-medium-font;
  font-size: 14px;
  font-weight: 500;
  color: $content-secondary;
  letter-spacing: -0.42px;
}

.cta {
  margin-top: 22px;
  font-family: $neue-grotesk-medium-font;
  font-size: 14px;
  font-weight: 500;
  text-align: left;
}

}