[widget-id='OVTwidget'] {
@import "../../mixins/global";

.progressBarAcuity {
  width: 100%;
  height: 32px;
  border-radius: 20px;
  border: 1px solid $border-color;
  background-color: $light-blue;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 5px;
}

.revert {
  transform: scale(-1, 1);
}

.progressBarValue {
  width: var(--progressBarValue);
  height: 22px;
  border-radius: 20px;
  background: $text-background-gradient-color;
  transition: width 0.5s;
}

}