[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

/* class applies to select element itself, not a wrapper element */
.selectState {
  width: 50%;

  @include phone-and-tablet-portrait {
    width: 100%;
  }
}

}