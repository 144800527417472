[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";
::-webkit-scrollbar {
  width: 10px;
  background-color: transparent;
  color: transparent;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($dark-blue-button-color, 0.7);
  // mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  z-index: 500;
  height: var(--vh-nobars, 100vh);
  overflow-y: auto;
  padding: $desktop-padding;
  animation: fadeIn 0.2s forwards;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }

    to {
      opacity: 1;
    }
  }

  &.fadingOut {
    animation: fadeOut 0.2s forwards;

    @keyframes fadeOut {
      from {
        opacity: 1;
      }

      to {
        opacity: 0;
      }
    }
  }

  @include phone-and-tablet-portrait {
    padding: $phone-padding;
  }
}

.modalBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: calc(756px + (80px * 2));
  height: 100%;
  background-color: white;
  padding: 0 16px 24px 16px;
  border-radius: 12px;
  overflow-y: hidden;
  max-height: 500px;

  @include phone-and-tablet-portrait {
    max-width: 100%;
  }
}

.titleRow {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin-bottom: 25px;
  position: relative;
  padding-top: 12px;
  position: sticky;
  top: 0;
  background: white;
}

.htmlTitle {
  font-family: $poppins-medium-font;
  font-size: 18px;
  font-weight: 500;
  color: $dark-blue-button-color;
  text-align: center;
  max-width: 213px;
  padding: 0 24px;
  letter-spacing: -0.18px;
}

.closeIcon {
  position: absolute;
  right: 0;
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  min-width: 0;
}

.descriptionSection {
  padding: 0 23px 0 10px;
  width: 100%;
  overflow-y: auto;
}

.description {
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  color: $dark-blue-button-color;

  b {
    font-family: $neue-grotesk-medium-font;
    font-weight: 500;
  }

  br {
    content: "";
    height: 20px;
    display: block;
  }
}

.fadeIn {
  opacity: 1;
  transition: opacity 0.3s ease-in-out;
}

.fadeOut {
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
}

}