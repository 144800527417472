[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.SelectPDSubTitle {
  font-family: $neue-grotesk-medium-font;
  font-size: 14px;
  font-weight: 500;
}

.SelectPDController {
  display: flex;
  gap: 10px 20px;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;

  &.DontKnowMyPDCase {
    opacity: 0.5;
    pointer-events: none;
  }

  .twoPDsLabels,
  .twoPDsInputs {
    width: 100%;
    display: flex;
    gap: 20px;
    justify-content: space-around;

    .rightPDLabel,
    .leftPDLabel {
      width: 50%;
    }

    @include phone-and-tablet-portrait {
      flex-direction: row;
      gap: 20px;
    }
  }

  .SelectPD {
    background: white;
    border-radius: 10px;
    width: 100%;

    // @include phone-and-tablet-portrait {
    //   width: 100%;
    // }
  }
}

.TwoPDsCheckbox {
  display: flex;
  font-family: $poppins-medium-font;
  font-size: 14px;
  font-weight: 500;
  line-height: 160%;
  margin-bottom: 25px;

  &.DontKnowMyPDCase {
    opacity: 0.5;
    pointer-events: none;
  }
}

hr {
  width: 100%;
  border: none;
  border-top: 1px solid $lavender-web;
  margin: 20px 0;
}

.SelectPDMobileButtonContainer {
  width: 100%;
  margin-bottom: 20px;

  .SelectPDMobileButton {
    display: flex;
    justify-content: flex-start;
    width: 100%;
    color: $tint-05-dark;
    font-family: $poppins-font;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 160%;
  }
}

}