[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

$header-height-desk: 72px;
$header-height-phone: 48px;

.HeaderContainer {
  width: 100%;
  background: white;
  border-bottom: 1px solid $border-color;
  position: sticky;
  top: 0;
  z-index: 150;
  transition: opacity 0.75s ease-out, transform 1.5s ease-out;

  &.hide {
    opacity: 0;
    transform: translateY(-50%);
  }

  &.show {
    opacity: 1;
    transform: translateY(0);
  }
}

.Header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: $header-height-desk;
  width: 100%;
  gap: 16px;
  font-size: 14px;
  padding: 0;

  @include phone-and-tablet-portrait {
    height: $header-height-phone;
  }
}

// .OnBackButton {
//   font-size: 12px;
//   font-style: normal;
//   font-weight: 500;
//   letter-spacing: -0.36px;
// }

.BrandImageButton {
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.BrandImage {
  flex-shrink: 0;
  max-width: 120px;
}

// .HiUser {
//   display: flex;
//   align-items: center;
//   font-size: 14px;

//   .UserImage {
//     margin-right: 10px;
//   }
// }

.leftElement {
  width: 50%;
}

.rightElement {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.onlyDesktop {
  @include phone-and-tablet-portrait {
    display: none;
  }
}

.onlyPhone {
  @include desktop-and-tablet-landscape {
    display: none;
  }
}

.userIcon {
  width: 40px;
  height: 40px;

  @include desktop-and-tablet-landscape {
    width: 72px;
    height: 72px;
  }
}

.moreInfoIcon {
  width: 40px;
  height: 40px;

  @include desktop-and-tablet-landscape {
    width: 72px;
    height: 72px;
  }
}

.moreInfoModal {
  position: fixed;
  top: $header-height-desk;
  left: 0;
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  z-index: 450;
  max-height: 100vh;
  overflow: visible;

  @include phone-and-tablet-portrait {
    top: $header-height-phone;
    background: rgba($dark-blue-button-color, 0.7);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px); //for safari
  }
}

.moreInfoModalPanel {
  padding: 5px 0 0;
  box-shadow: 0 8px 16px -2px rgba(#1b242c, 0.12),
    0 2px 2px -1px rgba(#1b242c, 0.04);
  border: solid 1px $border-color;
  border-radius: 12px 0 12px 12px;
  background-color: white;
  width: 100%;

  @include desktop-and-tablet-landscape {
    max-width: 400px;
    margin-left: auto;
  }
}

.moreInfoButton {
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  min-height: 48px;
  padding: 16px;
  box-sizing: border-box;
  text-align: left;
  justify-content: space-between;
  color: $dark-blue-button-color;
}

.moreInfoModalDivider {
  width: 100%;
  height: 1px;
  background-color: $border-color;
  margin-bottom: 5px;
}

.moreInfoButtonIcon {
  width: 20px;
  height: 20px;
}

.moreInfoCloseIcon {
  position: absolute;
  top: -$header-height-phone;
  right: 0;
  box-shadow: -2px -8px 11px -2px rgba(#1b242c, 0.12),
    0 2px 2px -3px rgba(#1b242c, 0.04);
  border: solid 1px $border-color;
  border-bottom: none;
  border-radius: 12px 12px 0 0;
  height: calc(#{$header-height-phone} + 2px);

  &:not(:disabled):hover {
    opacity: 1;
  }

  @include desktop-and-tablet-landscape {
    top: -$header-height-desk;
    height: calc(#{$header-height-desk} + 2px);
    width: $header-height-desk;
  }
}

}