[widget-id='OVTwidget'] {
@import "../../mixins/global";

.setupStep.pageCardMobile {
  .videoContainer {
    margin-top: 15px;
  }

  .footer {
    padding-bottom: 5px;
  }

  .footerShadow {
    box-shadow: none;
  }

  .none {
    display: none !important;
  }

  .description {
    br {
      content: "";
      display: block;
      height: 10px;
    }
  }

  .footerInlineButtonLabel {
    font-family: $neue-grotesk-font;
    font-size: 16px;
    font-weight: 400;
    color: $dark-blue-button-color;
    margin-top: 15px;
    text-align: center;
    padding: 0;
    width: 100%;
    text-decoration: underline;
    border: none;
    min-height: 0;
  }

  .footerLabelUnderButton {
    font-family: $neue-grotesk-font;
    font-size: 12px;
    font-weight: 400;
    color: $content-secondary;
    margin-top: 10px;
  }
}

}