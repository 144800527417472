[widget-id='OVTwidget'] {
@import "../../../mixins/global";

.radio {
  width: 16px;
  min-width: 16px;
  height: 16px;
  cursor: pointer;
  margin: 0;
  margin-right: 5px;

  appearance: none;
  -webkit-appearance: none;

  &:before {
    content: "";
    display: inline-block;
    width: 100%;
    height: 100%;
    border: 1px solid #000;
    border-radius: 50%;
    background-color: #fff;
    box-sizing: border-box;
  }

  &:checked:before {
    border: 5px solid black;
  }
}

label {
  cursor: pointer;
  font-family: $neue-grotesk-medium-font;
  font-size: 14px;
  font-weight: 500;
  color: $dark-blue-button-color;
  line-height: 1.4;
  letter-spacing: -0.42px;
}

.container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.containerBorder {
  width: 100%;
  max-width: 540px;
  border: $base-primary-lightest;
  border: 1px solid $base-other-color-1;
  padding: 16px;
  border-radius: 10px;
  cursor: pointer;

  &.checked {
    @include border-gradient(
      10px,
      2px solid transparent,
      $text-background-gradient-color
    );
  }

  .radio {
    &:checked {
      border: none;
      outline: none;
    }
  }
}

}