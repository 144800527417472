[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.SurveyBirthdayInputContainer {
  width: 100%;
}

.SurveyBirthdayInput {
  min-width: 300px;
  width: 20%;
  margin-top: 5px;

  @include phone-and-tablet-portrait {
    min-width: 0;
    width: 100%;
  }
}

.errorContainer {
  display: flex;
  gap: 5px;
  align-items: center;
  margin: 5px 0 0 16px;
}

}