[widget-id='OVTwidget'] {
@import "../../mixins/global.scss";

.geolocationButtonContainer {
  @include desktop-and-tablet-landscape {
    width: 300px;
  }

  @include phone-and-tablet-portrait {
    min-width: 0;
    width: 100%;
  }
}

.button {
  width: 100%;
  text-align: center;
  background: $border-color;
}

}