[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($dark-blue-button-color, 0.7);
  // mix-blend-mode: multiply;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px); //for safari
  z-index: 500;
  height: var(--vh-nobars, 100vh);
  overflow-y: auto;
  padding: $desktop-padding;

  @include phone-and-tablet-portrait {
    padding: $phone-padding;
  }
}

.modalBody {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 483px;
  //height: 100%;
  max-height: 100%;
  background-color: white;
  padding: 12px 16px;
  border-radius: 12px;

  @include phone-and-tablet-portrait {
    height: 100%;
    max-width: 100%;
  }
}

.titleRow {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
  padding-bottom: 27px;

  &.topShadow {
    box-shadow: 0px 16px 16px -16px $shadow-color-opacity,
      0px 2px 2px -2px rgba($shadow-color, 0.04);
  }
}

.titleContainer {
  display: flex;
  align-items: flex-start;

  p {
    font-family: $neue-grotesk-font;
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: -0.48px;
    text-align: left;
    color: $dark-blue-button-color;
  }
}

.titleIcon {
  width: 20px;
  height: 20px;
  margin: 2px 8px 2px 0;
  flex-shrink: 0;
}

.title {
  font-family: $neue-grotesk-medium-font;
  font-size: 16px;
  font-weight: 500;
  color: $dark-blue-button-color;
}

.closeIcon {
  width: 24px;
  height: 24px;
  flex-shrink: 0;
  min-width: 0;
}

.descriptionSection {
  overflow: auto visible;
  padding: 0 10px 0 3px;
  width: 100%;

  @include phone-and-tablet-portrait {
    height: 100%;
  }
}

.description {
  font-family: $neue-grotesk-font;
  font-size: 14px;
  font-weight: 400;
  color: $dark-blue-button-color;

  b {
    font-family: $neue-grotesk-medium-font;
    font-weight: 500;
  }

  br {
    content: "";
    height: 20px;
    display: block;
  }
}

.footer {
  margin-top: auto;
  padding-top: 15px;
  width: 100%;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1;

  &.bottomShadow {
    box-shadow: 0px -16px 16px -16px $shadow-color-opacity,
      0px -2px 2px -2px rgba($shadow-color, 0.04);
  }
}

.footerCta {
  @include phone-and-tablet-portrait {
    width: 100%;
  }
}

.none {
  display: none !important;
}

}