[widget-id='OVTwidget'] {
@import "../../../mixins/global.scss";

.SurveyStepButton {
  font-family: $neue-grotesk-font;
  color: $dark-blue-button-color;
  border-radius: 10px;
  background: transparent;
  padding: 10px 10px 10px 20px;
  text-align: center;
  // cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  border: none;
  height: 40px;
  width: 100%;
  margin: 0;

  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 140%;
  letter-spacing: -0.42px;

  &.disabled {
    color: $tint-05-dark-blurred;
    cursor: default;
    // box-shadow: inset 0px 0px 2px rgb(255 255 255 / 48%);
    // opacity: 0.4;
  }

  &.isOnFocus {
    background: $dark-blue-button-color;
    color: white;
  }

  &.stretch {
    display: block;
  }

  &:not(.disabled):hover {
    transition: opacity 0.2s;
    opacity: 0.9;
  }
}

.leftContent {
  display: flex;
  align-items: center;
  flex: 1;
}

.Icon {
  width: 20px;
  height: 20px;
}

.SurveyStepButtonContainer {
  width: 100%;

  &.isOnFocus {
    height: fit-content;
    display: flex;
    gap: 10px;
  }
}

.SurveyStepButtonNotch {
  &.isOnFocus {
    background-color: $fluorescent-blue-text-button-color;
    height: 40px;
    width: 4px;
    border-radius: 999px;
  }
}

}