[widget-id='OVTwidget'] {
@import "../../mixins/global";

.Background {
  .overPageContainer {
    margin-bottom: 10px;
  }

  .description {
    color: $content-secondary;
    font-size: 1.0625rem;
    margin-bottom: 20px;

    b {
      color: $dark-blue-button-color;
      font-family: $neue-grotesk-medium-font;
      font-weight: 500;
    }
  }
}

.pageBody {
  display: flex;
  flex-direction: column;
  //align-items: center;
  padding-left: 14.625rem;
  padding-right: 14.625rem;
  padding-top: 5rem;
  border-radius: 36px;
  background: white;
  height: 100%;

  @include phone-and-tablet-portrait {
    padding: 0;
    border-radius: 36px 36px 0px 0px;
  }
}

.continueToMobileButton {
  width: 100%;

  @include desktop-and-tablet-landscape {
    width: unset;
    margin-left: 15px;
  }

  @include phone-and-tablet-portrait {
    margin-top: 15px;
  }
}

}